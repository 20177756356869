<form (ngSubmit)="onConfirm()" *ngIf="active">
  <div class="modal-header">
    <label class="title pull-left">&nbsp; &nbsp; {{header}}</label>
    <button type="button" (click)="onCancel()" class="close pull-right" aria-label="Close">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    &nbsp; &nbsp; {{body}}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="onCancel()" >{{hayir}}</button>
    <button type="submit" class="btn btn-primary" > {{evet}} </button>
  </div>
</form>


