import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import {ApiService} from "./api.service";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class BagisciService {

  private PATH_BAGISCI_BILGILERI = environment.BAGIS.BAGISCI_BILGILERI;
  private PATH_YENI_BAGISCI_BILGILERI = environment.BAGIS.YENI_BAGISCI_BILGILERI;
  private PATH_MENKUL_BAGISCI_BILGILERI = environment.BAGIS.MENKUL_BAGIS_BAGISCI_BILGILERI;
  private PATH_GMENKUL_BAGISCI_BILGILERI = environment.BAGIS.GMENKUL_BAGIS_BAGISCI_BILGILERI;
  private PATH_GMENKUL_DETAY_BAGISCI_BILGILERI = environment.BAGIS.GMENKUL_BAGIS_DETAY_BAGISCI_BILGILERI;
  private PATH_KURBAN_BAGISCI_BILGILERI = environment.BAGIS.KURBAN_BAGIS_BAGISCI_BILGILERI;
  private PATH_ODUL_BELGE = environment.BAGIS.ODUL_BELGE


  constructor(private apiService: ApiService) { }

  getAll(tcknAndToken: any): Observable<any> {
    return this.apiService.post(this.PATH_BAGISCI_BILGILERI, tcknAndToken).pipe(map(
      res => {
        if (res) {
          return res;
        } else {
          console.log('get All fault' + res);
          return {}
        }
      }
    ));
  }


  updatebagisciBilgileri(bagisciBilgileri: any): Observable<any> {
    return this.apiService.put(this.PATH_YENI_BAGISCI_BILGILERI, bagisciBilgileri).pipe(
      res => {
        if (res) {
          return res;
        } else {
          console.log(res);
          return res;
        }
      }
    );
  }


  getmenkulBagisciBilgileri(tckn: any): Observable<any> {
    return this.apiService.post(this.PATH_MENKUL_BAGISCI_BILGILERI, tckn).pipe(map(
      res => {
        if (res) {
          return res;
        } else {
          console.log('get All fault' + res);
          return {}
        }
      }
    ));
  }

  getGMenkulBagisciBilgileri(tckn: any): Observable<any> {
    return this.apiService.post(this.PATH_GMENKUL_BAGISCI_BILGILERI, tckn).pipe(map(
      res => {
        if (res) {
          return res;
        } else {
          console.log('get All fault' + res);
          return {}
        }
      }
    ));
  }

  getGMenkulDetayBagisciBilgileri(tckn: any): Observable<any> {
    return this.apiService.post(this.PATH_GMENKUL_DETAY_BAGISCI_BILGILERI, tckn).pipe(map(
      res => {
        if (res) {
          return res;
        } else {
          console.log('get All fault' + res);
          return {}
        }
      }
    ));
  }

  getkurbanBagisciBilgileri(tckn: any): Observable<any> {
    return this.apiService.post(this.PATH_KURBAN_BAGISCI_BILGILERI, tckn).pipe(map(
      res => {
        if (res) {
          return res;
        } else {
          console.log('get All fault' + res);
          return {}
        }
      }
    ));
  }

  getOdulBilgileri(tckn: any): Observable<any> {
    return this.apiService.post(this.PATH_ODUL_BELGE, tckn).pipe(map(
      res => {
        if (res) {
          return res;
        } else {
          console.log('get All fault' + res);
          return {}
        }
      }
    ));
  }

}
