import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from "../security/authentication.service";
import {faUsers} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  usersIcon=faUsers
  currentYear: any =(new Date()).getFullYear();

  constructor(private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    // reset login status
    this.authenticationService.logout();
  }

}
