import {Component, OnInit, SystemJsNgModuleLoader} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {YardimAlanBilgileriService} from "../../services/yardim-alan-bilgileri.service";
import {NgxSpinnerService} from "ngx-spinner";
import {NotificationService} from "../../services/notification.service";
import {BagisciService} from "../../services/bagisci.service";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  adSoyad: string = '';
  karsilamaMesaji: string = '';
  altMesaj: string = '';
  currentTCKN: any;
  currentUserType:any;
  hitap: string='';
  kisiBilgisi: any;


  constructor(private translateService: TranslateService,
              private yardimAlanBilgileriService: YardimAlanBilgileriService,
              private spinner: NgxSpinnerService,
              private bagisciService: BagisciService,
              private notifyService: NotificationService,) {
  }

  ngOnInit(): void {
    this.spinner.show();
    this.langTurkce()
    let currentUser = JSON.parse(<string>localStorage.getItem('currentUser'));
    /*if (currentUser && currentUser.ad && currentUser.soyad) {
      this.adSoyad = currentUser.ad.toUpperCase() + ' ' + currentUser.soyad.toUpperCase();
    }*/
    this.currentTCKN={tckn:currentUser.tckn,ad:currentUser.ad,soyad:currentUser.soyad,}


    if (currentUser.kullaniciTipi == 1) {
      this.currentUserType=1;
      this.altMesaj = 'Soldaki menüyü kullanarak\n' +
        'kişisel bilgilerinize ve bağış bilgilerinize\n' +
        'ulaşabilirsiniz.'
      this.kisiBilgisi = {
        tckn: currentUser.tckn,
        currentToken:currentUser.token
      }
      this.getAllDataBagisYapan(this.kisiBilgisi)
    } else if (currentUser.kullaniciTipi == 2) {
      this.currentUserType=2;
      //Şehit-Gazi yakınları giriş yaptığında karşılama alanında şehit'e ait bilgiler görünecektir.
      this.getYardimAlanUstKayitBilgisi(this.currentTCKN)

    }
  }


  langTurkce() {
    this.translateService.use('tr')
  }

  getAllDataBagisYapan(kisiBilgisi: any) {
    this.bagisciService.getAll(kisiBilgisi).subscribe(
      response => {
        if (response.ad && response.soyad) {
          this.adSoyad = response.ad + ' ' + response.soyad;
        }this.spinner.hide();
      }, error => {
        this.spinner.hide();
      }
    )
  }

  getYardimAlanUstKayitBilgisi(kisiBilgisi: any) {
    this.yardimAlanBilgileriService.getYardimAlanUstKayitBilgisi(kisiBilgisi).subscribe(
      response => {
        this.adSoyad=response.ad.toUpperCase() + " "+response.soyad.toUpperCase();
        this.altMesaj = 'Soldaki menüyü kullanarak\n' +
          'kişisel bilgilerinize ve yardım bilgilerinize\n' +
          'ulaşabilirsiniz.'
        if (response.svgeDurum == 0){
          this.hitap="ŞEHİT MEHMETÇİĞİMİZ "
        }
        else if (response.svgeDurum == 4){
          this.hitap="ŞEHİT MEHMETÇİĞİMİZ "
        }
        else if (response.svgeDurum == 1){
          this.hitap="VEFAT MEHMETÇİĞİMİZ "
        }
        else {
          this.hitap="MEHMETÇİĞİMİZ "
        }

        this.spinner.hide();
      }, error => {
        this.notifyService.translateNotification(error.error, 'hata')
        this.spinner.hide();
      }
    )
  }


}
