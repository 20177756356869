import { Injectable } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private toastr: ToastrService,
              private translateService: TranslateService) {
  }

  showSuccess(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title,{positionClass:'toast-center-center'})
  }

  showError(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title, {tapToDismiss: true, disableTimeOut: true, positionClass:'toast-center-center'})

  }

  showInfo(message: string | undefined, title: string | undefined) {
    this.toastr.info(message, title)
  }

  showWarning(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title)
  }

  //Toaster mesajlarının içeriğinin localization ile düzenlenmesini sağlar
  translateNotification(mesaj: any, durum: any) {

    //Durum kontrolü ile toastr için showSuccess çağırımını gerçekleştirir.
    if (durum == "basarili") {
      this.translateService.get(mesaj).subscribe(
        //localization dosyasına gider ve component.ts'ler içerisinde belirlenmiş mesaj içeriğinin karşılığını çağırır
        (res: string) => {
          this.translateService.get(durum).subscribe(
            //localization dosyasına gider ve component.ts'ler içerisinde belirlenmiş durum içeriğinin karşılığını çağırır
            (res2: string) => {
              this.showSuccess(res, res2)
            }
          );
        }
      );

      //this.notifyService.showSuccess("Güncelleme Başarılı", "BAŞARILI")

      //Durum kontrolü ile toastr için showError çağırımını gerçekleştirir.
    } else if (durum == "hata") {

      this.translateService.get(mesaj.code).subscribe(
        //localization dosyasına göre hata mesajı içerisinde backendden gelen code aranır ve açıklaması alınır.
        (res: string) => {
          if (res == mesaj.code) {
            //localization dosyasına göre hata mesajı içeriği eğer hata coduna eşit ise hata (error.message) içeriği açıklama olarak kullanılır.
            this.translateService.get(durum).subscribe(
              //locale durum çekilir
              (res2: string) => {
                this.showError(mesaj.message, res2)
              }
            );
          } else {
            this.translateService.get(durum).subscribe(
              //locale durum çekilir
              (res2: string) => {
                this.showError(res, res2)
              }
            );
          }
        },
        error => {
          this.translateService.get(durum).subscribe(
            //localization içerisinde yer almayan bir kod backendden gönderilirse mesaj (error.message) olarak yayınlanır.
            (res2: string) => {
              this.showError(mesaj.message, res2)
            }
          );
        }
      )
      /* }
       ;*/


      //this.notifyService.showError("HATA", "İşlem İptal Edildi")
      //Durum kontrolü ile toastr için showInfo çağırımını gerçekleştirir.
    } else if (durum == "bildirim") {
      this.translateService.get(mesaj).subscribe(
        (res: string) => {
          this.translateService.get(durum).subscribe(
            (res2: string) => {
              this.showInfo(res, res2)
            }
          );
        }
      );
      //Durum kontrolü ile toastr için showWarning çağırımını gerçekleştirir.
    } else if (durum == "uyari") {
      this.translateService.get(mesaj).subscribe(
        (res: string) => {
          this.translateService.get(durum).subscribe(
            (res2: string) => {
              this.showWarning(res, res2)
            }
          );
        }
      );
    }
  }
}
