import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {NotificationService} from "../../services/notification.service";
import {SifreService} from "../../services/sifre.service";
import {Router} from "@angular/router";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-sifre-unuttum',
  templateUrl: './sifre-unuttum.component.html',
  styleUrls: ['./sifre-unuttum.component.scss']
})
export class SifreUnuttumComponent implements OnInit {

  tckn: any;
  sifreForm: FormGroup | any;
  modalRef: BsModalRef | any;

  @ViewChild('tplBildirim', {static: true}) tplBekleyiniz !: TemplateRef<any>


  constructor(private formBuilder: FormBuilder,
              private notifyService: NotificationService,
              private sifreService: SifreService,
              private router: Router,
              private modalService: BsModalService,
  ) {
  }

  ngOnInit(): void {
    this.sifreForm = this.formBuilder.group({
      "tckimlikNo": [{
        value: null,
        disabled: false
      }]
    })
  }


  send() {

    this.sifreService.resetPassword(this.sifreForm.value).subscribe(
      response => {
        this.notifyService.translateNotification('Şifre sıfırlama talebiniz alınmıştır.', 'Başarılı')
      },
      error => {
        this.notifyService.translateNotification(error.error, 'Hata')
      }
    )
    this.showModal();
  }

  showModal() {
    /*this.modalRef = this.modalService.show(templateDetay);*/
    this.modalRef = this.modalService.show(this.tplBekleyiniz, {class: 'modal-md'});
  }


  closeAndResetModal() {
    this.modalService.hide();
  }
}

