<div class="container">

  <div class=" offset-3"><label style=" padding-top: 2%"><b> </b>
  </label></div>
  <div class="row">
    <div class="col-sm">

      <div class="form-row">
        <div class="form-group col-md-2"></div>
        <div *ngIf="currentUserType==1" class="form-group col-md-8" align="center">
          <label style="font-size: 20px"><b>TSK MEHMETÇİK VAKFI DEĞERLİ BAĞIŞÇIMIZ <a style="color: red">{{adSoyad.toUpperCase()}}'E </a> ŞÜKRANLARINI SUNAR.</b></label>
        </div>
        <div  *ngIf="currentUserType==2" class="form-group col-md-8" align="center">
          <label style="font-size: 20px"><b>TSK MEHMETÇİK VAKFI {{hitap}} <a style="color: red">{{adSoyad.toUpperCase()}}'A </a> ŞÜKRANLARINI SUNAR.</b></label>
        </div>
        <div class="form-group col-md-2"></div>
      </div>


      <div class="form-row">
        <div class="form-group col-md-4"></div>
        <div class="form-group col-md-4" align="center">
          <label style="font-size: 16px">{{altMesaj}}</label>
        </div>
        <div class="form-group col-md-4"></div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-4"></div>
        <div class="form-group col-md-4" align="center">
          <img [src]="'assets/logo_tsk.png'" class="img-fluid" width="300" alt="Image Source">
        </div>
        <div class="form-group col-md-4"></div>

        <!--[ngStyle]="{background:'url(assets/logo_tsk.png)','background-repeat': 'no-repeat', 'background-position': 'center center',  'background-size': '12% '}"-->

      </div>
    </div>
  </div>
</div>


<!-- Default form subscription -->

<!--
<div class="card" style="width: 100rem; weight:100rem" align="center">
<div class="card center_div" style="width: 25rem;" align="center">
  <div class="card-header">
  </div>
  <ul class="list-group list-group-flush">
    <li class="list-group-item font-weight-bold" >  {{karsilamaMesaji}} </li>
    <li class="list-group-item"> </li>
    <li class="list-group-item">  {{altMesaj}} </li>
  </ul>
</div>
</div>-->
