<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">Şifre Değiştirme Sayfası</li>
  </ol>
</nav>
<div style="padding-top: 5% ">
  <form [formGroup]="sifreForm" (ngSubmit)="update()">

    <div class="row" align="center">
      <div class="col-md-4"></div>
      <div class="col-md-4">
        <label style="color: blue"><b> T.C. Kimlik No. :</b><b> {{tckn}} </b></label>
      </div>
      <div class="col-md-4"></div>
    </div>

    <br>

    <div class="row" align="center">
      <div class="col-md-4"></div>
      <div class="col-md-4">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <div class="form-group">
            <label><b>{{sifreAdi}}</b></label>
          </div>

          <div class="input-group">

            <input [type]="fieldEskiSifreTextType ? 'text' : 'password'" formControlName="eskiSifre" minlength="8"
                   maxlength="8" class="form-control"
                   autocomplete="off"
                   [ngClass]="{'is-invalid': f.eskiSifre.errors }"/>
            <div class="input-group-append">
            <span class="input-group-text">
              <i (click)="togglefieldEskiSifreTextType()">
                <fa-icon [icon]="eyeIcon" data-toggle="tooltip" data-placement="top"
                         title="{{'gosterGizle' | translate}}"></fa-icon>
              </i>
            </span>
            </div>


          </div>
        </div>
        <div class="col-md-2"></div>
        <div class="col-md-4"></div>
      </div>
    </div>

    <br>

    <div class="row" align="center">
      <div class="col-md-4"></div>
      <div class="col-md-4">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <div class="form-group">
            <label><b>{{'yeniSifre' | translate}}</b></label>
            <div class="input-group">

              <input [type]="fieldTextType ? 'text' : 'password'" formControlName="yeniSifre" class="form-control"
                     autocomplete="off"
                     [ngClass]="{'is-invalid': f.yeniSifre.errors }" id="yeniSifre" maxlength="8"/>

              <div class="input-group-append">
              <span class="input-group-text">
                <i (click)="toggleFieldTextType()"> <fa-icon [icon]="eyeIcon" data-toggle="tooltip" data-placement="top"
                                                             title="{{'gosterGizle' | translate}}"></fa-icon></i>
              </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2"></div>
        <div class="col-md-4"></div>
      </div>
    </div>

    <br>

    <div class="row" align="center">
      <div class="col-md-4"></div>
      <div class="col-md-4">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <div class="form-group">
            <label><b>{{'yeniSifreTekrar' | translate}}</b></label>
            <div class="input-group">
              <input [type]="repeatFieldTextType ? 'text' : 'password'" formControlName="yeniSifreTekrar"
                     class="form-control"
                     autocomplete="off"
                     [ngClass]="{'is-invalid': f.yeniSifreTekrar.errors }" id="yeniSifreTekrar" maxlength="8"/>

              <div class="input-group-append">
      <span class="input-group-text">
        <i (click)="toggleRepeatFieldTextType()"> <fa-icon [icon]="eyeIcon" data-toggle="tooltip"
                                                           data-placement="top"
                                                           title="{{'gosterGizle' | translate}}"></fa-icon></i>

      </span>
              </div>


            </div>
          </div>
        </div>
        <div class="col-md-2"></div>
        <div class="col-md-4"></div>
      </div>
    </div>


    <div class="form-group" align="center">
      <button type="submit" class="btn btn-primary" data-toggle="tooltip"
              data-placement="top"
              title="{{'sifremiDegistir' | translate}}">{{'sifremiDegistir' | translate}}</button>
    </div>

    <div class="row">
      <div class="col-md-4"></div>
      <div class="col-md-4">

        <div *ngIf=" f.eskiSifre.errors && submitted" style="color: red">
          <div *ngIf="f.eskiSifre.errors.required">{{'validEskiSifre' | translate}}</div>
        </div>

        <div *ngIf=" f.yeniSifre.errors && submitted" style="color: red">
          <div *ngIf="f.yeniSifre.errors.pattern">"Şifrenizin en az 2 karakteri rakam olmalıdır</div>
          <div *ngIf="f.yeniSifre.errors.minlength">{{'validSifre8' | translate}}</div>
          <div *ngIf="f.yeniSifre.errors.required">{{'validYeniSifre' | translate}}</div>
        </div>

        <div *ngIf=" f.yeniSifreTekrar.errors && submitted" style="color: red">
          <div *ngIf="f.yeniSifreTekrar.errors.minlength">{{'validSifre8' | translate}}!</div>
          <div *ngIf="f.yeniSifreTekrar.errors.maxlength">{{'validSifre8' | translate}}!</div>
          <div *ngIf="f.yeniSifreTekrar.errors.required">{{'validYeniSifreTekrar' | translate}}</div>
          <div *ngIf="f.yeniSifreTekrar.errors">-Girilen şifreler aynı olmalıdır.</div>
        </div>
      </div>
      <div class="col-md-4"></div>
    </div>

  </form>
</div>
<div class="row">
  <div class="col-md-3"></div>
  <div class="col-md-6">
    <label style="color: red; padding-top: 2%"> Açıklama:<br>
      Yeni şifreniz 8 karakter uzunluğunda ve en az 2 karakteri rakam olmalıdır.<br>
      Sisteme ilk defa giriş yapıyorsanız geçici şifrenizi değiştirmeniz gerekmektedir.
    </label>
  </div>
  <div class="col-md-3"></div>
</div>


<ng-template #tplBekleyiniz>


  <div class="modal-header ">
    <label class="title-modal">{{'yeniSifreOlusturuldu' | translate}}</label>
  </div>
  <div class="modal-body">

    <div> {{message}}</div>

  </div>


</ng-template>



