import {Component, OnInit} from '@angular/core';
import {YardimAlanBilgileriService} from "../../../services/yardim-alan-bilgileri.service";
import {NgxSpinnerService} from "ngx-spinner";
import {NotificationService} from "../../../services/notification.service";
import {DomSanitizer} from "@angular/platform-browser";
import {DatePipe} from "@angular/common";
import {CommonService} from "../../../services/common.service";

@Component({
  selector: 'app-yardim-alan-bilgileri',
  templateUrl: './yardim-alan-bilgileri.component.html',
  styleUrls: ['./yardim-alan-bilgileri.component.scss']
})
export class YardimAlanBilgileriComponent implements OnInit {

  pipe = new DatePipe('tr-TR'); // Use your own locale

  tckn: string = ""
  ad: string = '';
  babaAdi: string = '';
  adSoyad: string = '';
  dogumYeri: string = '';
  dogumTarihi: string | null = '';
  birlikAdi: string = '';
  olayYeri: string = '';
  olayTarihi: string | null = '';
  durumu: string = '';
  svgeDurum: string = '';//Gazi- şehit durumu için kullanılacak
  sakatlikDerecesi: string = '';//Gazi- şehit durumu için kullanılacak
  vefatEngellikSebebi: string = '';
  image: any;
  imageSource: any;
  kisiBilgisi: any;
  currentToken:any;
  imageFlag: any;


  /* kisiBilgisi = {
     tckn: '24790034076'
   }*/

  constructor(private yardimAlanBilgileriService: YardimAlanBilgileriService,
              private notifyService: NotificationService,
              private spinner: NgxSpinnerService,
              private sanitizer: DomSanitizer) {

    let currentUser = JSON.parse(<string>localStorage.getItem('currentUser'));
    this.tckn = currentUser.tckn
    this.currentToken = currentUser.token

    this.kisiBilgisi = {
      tckn: this.tckn,
      currentToken:this.currentToken
    }
  }

  ngOnInit(): void {


    this.showSpinner();
    this.getAllData()

  }


  getAllData() {

    this.yardimAlanBilgileriService.getAll(this.kisiBilgisi).subscribe(
      response => {
        this.tckn = response.tckn
        if (response.ad && response.soyad) {
          this.adSoyad = response.ad + ' ' + response.soyad;
        }
        this.babaAdi = response.babaAdi
        this.dogumTarihi = this.pipe.transform(response.dogumTarihi, 'dd.MM.yyyy');
        this.dogumYeri = response.dogumYeri
        this.birlikAdi = response.birlikAdi
        this.olayYeri = response.olayYeri
        this.olayTarihi = this.pipe.transform(response.olayTarihi, 'dd.MM.yyyy');
        this.svgeDurum = response.svgeDurum;
        this.sakatlikDerecesi = response.sakatlikDerecesi;
        this.vefatEngellikSebebi = response.vefatEngellikSebebi;

        this.kisiDurumuBirlestir(this.svgeDurum, this.sakatlikDerecesi);

        this.image = response.kisininResmi

        this.imageFlag = false;
        if (this.image) {
          this.imageFlag = true;
        }
        this.imageSource = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${this.image}`);
        this.spinner.hide();

      }, error => {
        this.notifyService.translateNotification(error.error, 'hata')
        this.spinner.hide();
      }
    )
  }

  showSpinner() {
    this.spinner.show();
  }

  kisiDurumuBirlestir(svgeDurum: string, sakatlikDerecesi: string) {


    if (svgeDurum == '0') {
      this.durumu = 'Şehit'
    } else if (svgeDurum == '1') {
      this.durumu = 'Vefat'
    } else if (svgeDurum == '2') {
      this.durumu = 'Gazi'
    } else if (svgeDurum == '3') {
      this.durumu = 'Engelli'
    }

    if (sakatlikDerecesi == '1') {
      this.durumu = this.durumu + ' 1.Derece';
    } else if (sakatlikDerecesi == '2') {
      this.durumu = this.durumu + ' 2.Derece';
    }
    if (sakatlikDerecesi == '3') {
      this.durumu = this.durumu + ' 3.Derece';
    }
    if (sakatlikDerecesi == '4') {
      this.durumu = this.durumu + ' 4.Derece';
    }
    if (sakatlikDerecesi == '5') {
      this.durumu = this.durumu + ' 5.Derece';
    }
    if (sakatlikDerecesi == '6') {
      this.durumu = this.durumu + ' 6.Derece';
    }


  }
}
