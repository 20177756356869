import {CUSTOM_ELEMENTS_SCHEMA, DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SidebarComponent} from './layout/sidebar/sidebar.component';
import {HeaderComponent} from './layout/header/header.component';
import {AppLayoutComponent} from './layout/app-layout/app-layout.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {NotFoundComponent} from './shared/not-found/not-found.component';
import {YetkisizIstekComponent} from "./shared/yetkisiz-istek/yetkisiz-istek.component";
import {MainComponent} from './main/main.component';
import {BagisciBilgileriComponent} from './pages/bagis/bagisci-bilgileri/bagisci-bilgileri.component';
import {BagisBilgileriComponent} from './pages/bagis/bagis-bilgileri/bagis-bilgileri.component';
import {YardimAlanBilgileriComponent} from './pages/yardim/yardim-alan-bilgileri/yardim-alan-bilgileri.component';
import {YardimDurumuComponent} from './pages/yardim/yardim-durumu/yardim-durumu.component';
import {SifreDegistirComponent} from './pages/sifre-degistir/sifre-degistir.component';
import {CurrencyPipe, DatePipe, registerLocaleData} from "@angular/common";
import localeTR from "@angular/common/locales/tr";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {ToastrModule} from "ngx-toastr";
import {NgxChartsModule} from "@swimlane/ngx-charts";
import {MatIconModule} from "@angular/material/icon";
import {FormBuilder, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {PositioningService} from "ngx-bootstrap/positioning";
import {ComponentLoaderFactory} from "ngx-bootstrap/component-loader";
import {BsModalService} from "ngx-bootstrap/modal";
import {NgxSpinnerModule} from "ngx-spinner";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {SharedModule} from "./shared/shared.module";
import {LoginComponent} from './login/login.component';
import {AuthGuard} from "./security/auth.guard";
import {ErrorInterceptor} from "./security/authentication.interceptor";
import {JwtInterceptor} from "./security/jwt.interceptor";
import {RegisterComponent} from './register/register.component';
import {NgxMaskModule} from "ngx-mask";
import {FooterComponent} from './layout/footer/footer.component';
import {SifreUnuttumComponent} from './pages/sifre-unuttum/sifre-unuttum.component';
import {RecaptchaFormsModule, RecaptchaModule} from "ng-recaptcha";


export const createTranslateLoader = (http: HttpClient) => {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

registerLocaleData(localeTR);

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    HeaderComponent,
    AppLayoutComponent,
    DashboardComponent,
    YetkisizIstekComponent,
    NotFoundComponent,
    MainComponent,
    BagisciBilgileriComponent,
    BagisBilgileriComponent,
    YardimAlanBilgileriComponent,
    YardimDurumuComponent,
    SifreDegistirComponent,
    LoginComponent,
    RegisterComponent,
    FooterComponent,
    SifreUnuttumComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    SharedModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    HttpClientModule,
    MatIconModule,
    FontAwesomeModule,
    NgxChartsModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    NgxSpinnerModule,
    BsDropdownModule.forRoot(),
    NgxMaskModule.forRoot(),
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  providers: [BsModalService,
    ComponentLoaderFactory,
    PositioningService,
    FormBuilder,
    {provide: LOCALE_ID, useValue: 'tr'},
    {provide: DEFAULT_CURRENCY_CODE, useValue: '₺'},
    CurrencyPipe,
    DatePipe,
    AuthGuard,
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    TranslateModule,
  ]
})
export class AppModule {
}
