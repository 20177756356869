<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">Şifre Sıfırmala Sayfası</li>
  </ol>
</nav>
<div class="page-wrapper" style="padding-top: 5% ">
  <div class="col-md-2 offset-5">
    <form [formGroup]="sifreForm" (ngSubmit)="send()">


      <div class="form-group">
        <label><b>T.C. Kimlik No. :</b></label>

          <div class="row">
            <div class="col-md-12">
          <input type="text" formControlName="tckimlikNo" class="form-control" maxlength="11"/>
            </div>
          </div>


      </div>


      <div class="form-group">
<div class="row">
  <div class="col-md-5">
        <button [routerLink]="['/bas-bss/login']" class="btn btn-primary" data-toggle="tooltip"
                data-placement="top"
                title="Ana Sayfa'ya dönmek için tıklayınız"> Ana Sayfa
        </button>
  </div>


  <div class="col-md-5">
        <button type="submit" class="btn btn-warning" data-toggle="tooltip"
                data-placement="top"
                title="Şifreyi sıfırlamak için tıklayınız">Şifremi Sıfırla
        </button>
  </div>

</div>
      </div>



    </form>
  </div>
  <div class="col-md-6 offset-4 "><label style="color: red; padding-top: 2%"> Açıklama:<br>
    Şifrenizin sıfırlanması için sistemde daha önce kayıtlı olmanız gerekir.<br>
    Eğer sistemde kayıtlı iseniz giriş bilgileriniz EPosta olarak gönderilecektir.
  </label></div>


</div>

<ng-template #tplBildirim>


  <div class="modal-header ">
    <label class="title-modal">Bildirim Mesajı</label>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div><b>Şifre sıfırlama isteğinize ilişkin bilgileriniz Sistemde kayıdınız olması durumunda mail adresinize
      gönderilecektir.</b></div>

  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="closeAndResetModal()" data-toggle="tooltip"
            data-placement="top" title="Kapat">Kapat
    </button>

  </div>


</ng-template>

