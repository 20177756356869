import { Injectable } from '@angular/core';
import {ApiService} from "./api.service";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class YardimAlanBilgileriService {

  private PATH_YARDIM_ALAN_BILGILERI = environment.YARDIM.YARDIM_ALAN_BILGILERI;
  private YARDIM_ALAN_KISI_VE_YAKIN_BILGILERI = environment.YARDIM.YARDIM_ALAN_KISI_VE_YAKIN_BILGILERI ;
  private YARDIM_OLUM_VE_MALULIYET_BILGILERI = environment.YARDIM.YARDIM_OLUM_VE_MALULIYET_BILGILERI;
  private SUREKLI_YARDIM_BAKIM = environment.YARDIM.SUREKLI_YARDIM_BAKIM;
  private SUREKLI_YARDIM_BAKIM_OGRENIM = environment.YARDIM.SUREKLI_YARDIM_BAKIM_OGRENIM;
  private YARDIM_COCUK_DOGUM_BILGISI = environment.YARDIM.YARDIM_COCUK_DOGUM_BILGISI;
  private YARDIM_COCUK_OLUM_BILGISI = environment.YARDIM.YARDIM_COCUK_OLUM_BILGISI;
  private YARDIM_MALUL_OLUM_BILGISI = environment.YARDIM.YARDIM_MALUL_OLUM_BILGISI;
  private YARDIM_ANNELER_GUNU_BILGISI = environment.YARDIM.YARDIM_ANNELER_GUNU_BILGISI;
  private YARDIM_BABALAR_GUNU_BILGISI = environment.YARDIM.YARDIM_BABALAR_GUNU_BILGISI;
  private UPDATE_YARDIM_ALAN_BILGILERI = environment.YARDIM.UPDATE_YARDIM_ALAN_BILGILERI;
  private YARDIM_ALAN_UST_KAYIT_BILGILERI = environment.YARDIM.YARDIM_ALAN_UST_KAYIT_BILGILERI;
  private KISI_BILGISI = environment.YARDIM.YARDIM_ALAN_KISI_BILGILERI;





  constructor(private apiService: ApiService) { }

  getAll(tckn: any): Observable<any> {
    return this.apiService.post(this.PATH_YARDIM_ALAN_BILGILERI, tckn).pipe(map(
      res => {
        if (res) {
          return res;
        } else {
          console.log('get All fault' + res);
          return {}
        }
      }
    ));
  }

  getKisiBilgisi(tckn: any): Observable<any> {
    return this.apiService.post(this.KISI_BILGISI, tckn).pipe(map(
      res => {
        if (res) {
          return res;
        } else {
          console.log('get All fault' + res);
          return {}
        }
      }
    ));
  }

  getYardimAlanYakiniBilgileri(tckn: any): Observable<any> {

    return this.apiService.post(this.YARDIM_ALAN_KISI_VE_YAKIN_BILGILERI, tckn).pipe(map(
      res => {
        if (res) {
          return res;
        } else {
          console.log('get All fault' + res);
          return {}
        }
      }
    ));
  }

  getYardimOlumVeMaluliyetBilgileri(tckn: any): Observable<any> {
    return this.apiService.post(this.YARDIM_OLUM_VE_MALULIYET_BILGILERI, tckn).pipe(map(
      res => {
        if (res) {
          return res;
        } else {
          console.log('get All fault' + res);
          return {}
        }
      }
    ));
  }

  getSurekliYardimBakimBilgileri(tckn: any): Observable<any> {
    return this.apiService.post(this.SUREKLI_YARDIM_BAKIM, tckn).pipe(map(
      res => {
        if (res) {
          return res;
        } else {
          console.log('get All fault' + res);
          return {}
        }
      }
    ));
  }

  getSurekliYardimBakimOgrenimBilgileri(tckn: any): Observable<any> {
    return this.apiService.post(this.SUREKLI_YARDIM_BAKIM_OGRENIM, tckn).pipe(map(
      res => {
        if (res) {
          return res;
        } else {
          console.log('get All fault' + res);
          return {}
        }
      }
    ));
  }

  getYardimCocukOlumBilgileri(tckn: any): Observable<any> {
    return this.apiService.post(this.YARDIM_COCUK_OLUM_BILGISI, tckn).pipe(map(
      res => {
        if (res) {
          return res;
        } else {
          console.log('get All fault' + res);
          return {}
        }
      }
    ));
  }

  getYardimCocukDogumBilgileri(tckn: any): Observable<any> {
    return this.apiService.post(this.YARDIM_COCUK_DOGUM_BILGISI, tckn).pipe(map(
      res => {
        if (res) {
          return res;
        } else {
          console.log('get All fault' + res);
          return {}
        }
      }
    ));
  }

  getYardimMalulOlumBilgileri(tckn: any): Observable<any> {
    return this.apiService.post(this.YARDIM_MALUL_OLUM_BILGISI, tckn).pipe(map(
      res => {
        if (res) {
          return res;
        } else {
          console.log('get All fault' + res);
          return {}
        }
      }
    ));
  }

  getYardimAnnelerGunuBilgileri(tckn: any): Observable<any> {
    return this.apiService.post(this.YARDIM_ANNELER_GUNU_BILGISI, tckn).pipe(map(
      res => {
        if (res) {
          return res;
        } else {
          console.log('get All fault' + res);
          return {}
        }
      }
    ));
  }

  getYardimBabalarGunuBilgileri(tckn: any): Observable<any> {
    return this.apiService.post(this.YARDIM_BABALAR_GUNU_BILGISI, tckn).pipe(map(
      res => {
        if (res) {
          return res;
        } else {
          console.log('get All fault' + res);
          return {}
        }
      }
    ));
  }

  updateYardimAlanBilgileri(yardimAlanBilgileri: any): Observable<any> {
    return this.apiService.put(this.UPDATE_YARDIM_ALAN_BILGILERI, yardimAlanBilgileri).pipe(
      res => {
        if (res) {
          return res;
        } else {
          console.log(res);
          return res;
        }
      }
    );
  }

  getYardimAlanUstKayitBilgisi(tckn: any): Observable<any> {
    return this.apiService.post(this.YARDIM_ALAN_UST_KAYIT_BILGILERI, tckn).pipe(map(
      res => {
        if (res) {
          return res;
        } else {
          console.log('get All fault' + res);
          return {}
        }
      }
    ));
  }

}
