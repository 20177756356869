import { Component, OnInit } from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {TranslateService} from "@ngx-translate/core";
import {Subject} from "rxjs";

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {

  body: string | any;
  header: string | any;
  evet: string | any;
  hayir: string | any;
  public onClose: Subject<boolean> | any;
  public active!: boolean;

  constructor(private bsModelRef: BsModalRef,private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.onClose = new Subject();
    this.translateService.get('evet').subscribe(
      //localization dosyasına gider ve component.ts'ler içerisinde belirlenmiş durum içeriğinin karşılığını çağırır
      (res: string) => {
        this.evet = res;
      }
    );
    this.translateService.get('hayir').subscribe(
      //localization dosyasına gider ve component.ts'ler içerisinde belirlenmiş durum içeriğinin karşılığını çağırır
      (res: string) => {
        this.hayir = res;
      }
    );
  }

  //Her yerden aynı bilgiler ile çağırılması sebbei ile içerik bir noktadan localization ile birlikte handle edildi
  /*  public showConfimation(header: string, body: string): void {
      this.body = body;
      this.header = header;
      this.active = true;

    }*/

  public showConfimation(): void {
    this.translateService.get('deleteOnay').subscribe(
      //localization dosyasına gider ve component.ts'ler içerisinde belirlenmiş durum içeriğinin karşılığını çağırır
      (res: string) => {
        this.body = res;
      }
    );
    this.translateService.get('deleteHeader').subscribe(
      //localization dosyasına gider ve component.ts'ler içerisinde belirlenmiş durum içeriğinin karşılığını çağırır
      (res: string) => {
        this.header = res;
      }
    );
    this.active = true;
  }

  onConfirm() {
    this.active = false;
    this.onClose.next(true);
    this.bsModelRef.hide();
  }

  onCancel() {
    this.active = false;
    this.onClose.next(false);
    this.bsModelRef.hide();

  }

}
