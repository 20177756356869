export let YakinlikDerecesiEnum = {
  Kendisi: {variable: 1, display: 'Kendisi'},
  Anne: {variable: 2, display: 'Anne'},
  Baba: {variable: 3, display: 'Baba'},
  Es: {variable: 4, display: 'Eş'},
  Cocuk: {variable: 5, display: 'Çocuk'},
  Vasi: {variable: 6, display: 'Vasi'},
  Kardes: {variable: 7, display: 'Kardeş'}
}

