<header class="header sticky-top" style="background-color: #387AB0 !important;">
  <nav class="navbar navbar-light px-sm-4 ">
    <IMG SRC="../../../assets/logo_tsk.png" width="80">
    <!--<div class="col-md-1"> </div>-->
    <div style="color: white !important; font-family: 'Arial Black'">
      <span >TSK Mehmetçik Vakfı<br></span>
        <span><b style="font-size: 60%">&emsp;Bireysel Sorgulama ve Denetim Sistemi</b></span></div>

    <ul class="navbar-nav flex-row ml-auto" data-toggle="tooltip" data-placement="top"
        title="Çıkış yapmak için tıklayınız.">
      <div class='navbar-brand' style="color: white !important;">{{adSoyad}}</div>
      <a class="dropdown-item" style="background: green; color: whitesmoke" [routerLink]="['/bas-bss/main']"
         *ngIf="!(this.router.url.includes('/bas-bss/login') || this.router.url.includes('/bas-bss/main') || this.router.url.includes('/register'))">
        <fa-icon [icon]="signOutIcon"></fa-icon></a>
    </ul>

  </nav>

</header>
