<div class="content-wrapper">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">Kişisel Bilgileriniz</li>
      <!--<li class="breadcrumb-item" aria-current="page">{{'bagisBildirimleri'|translate}}</li>-->
    </ol>
  </nav>
  <div class="container">

    <div class="col-md-6 offset-3 "><label style=" padding-top: 2%"><b> </b>
    </label></div>
    <div class="row">
      <div class="col-sm">

        <div class="form-row">
          <div class="form-group col-md-2"></div>
          <div class="form-group col-md-4">
            <label><b>T.C. Kimlik No.</b></label>
          </div>
          <div class="col-md-1">:</div>
          <div class="form-group col-md-3">
            <label>{{tckn}}</label>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-2"></div>
          <div class="form-group col-md-4" >
            <label><b>Adı ve Soyadı</b></label>
          </div>
          <div class="col-md-1">:</div>
          <div class="form-group col-md-3" align="left">
            <label>{{adSoyad}}</label>
          </div>

        </div>


        <div class="form-row">
          <div class="form-group col-md-2"></div>
          <div class="form-group col-md-4">
            <label><b>Adresi</b></label>
          </div>
          <div class="col-md-1">:</div>
          <div class="form-group col-md-3">
            <label>{{adres}}</label>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-2"></div>
          <div class="form-group col-md-4">
            <label><b>Adres İlçesi</b></label>
          </div>
          <div class="col-md-1">:</div>
          <div class="form-group col-md-3">
            <label>{{ilceAdi}}</label>
          </div>
        </div>


        <div class="form-row">
          <div class="form-group col-md-2"></div>
          <div class="form-group col-md-4">
            <label><b>Adres İli</b></label>
          </div>
          <div class="col-md-1">:</div>
          <div class="form-group col-md-3">
            <label>{{adresIl}}</label>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-2"></div>
          <div class="form-group col-md-4">
            <label><b>Ev Telefonu</b></label>
          </div>
          <div class="col-md-1">:</div>
          <div class="form-group col-md-3">
            <label>{{evTel}}</label>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-2"></div>
          <div class="form-group col-md-4">
            <label><b>Cep Telefonu</b></label>
          </div>
          <div class="col-md-1">:</div>
          <div class="form-group col-md-3">
            <label>{{cepTel}}</label>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-2"></div>
          <div class="form-group col-md-4">
            <label><b>İş Telefonu</b></label>
          </div>
          <div class="col-md-1">:</div>
          <div class="form-group col-md-3">
            <label>{{isTel}}</label>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-2"></div>
          <div class="form-group col-md-4">
            <label><b>E-Posta Adresi</b></label>
          </div>
          <div class="col-md-1">:</div>
          <div class="form-group col-md-3">
            <label>{{email}}</label>
          </div>
        </div>


      </div>


      <div class="col-sm"*ngIf="!imageFlag"></div>

      <div class="col-sm" *ngIf="imageFlag">
        <img [src]="imageSource" class="img-fluid" width="200">
      </div>
    </div>

    <div class="row">
      <div class="form-group col-md-4"></div>
      <div class="form-group col-md-4">
        <button type="button" class="btn btn-link" (click)="showModal(templateEdit)">Bilgileri güncelleştirmek
          istiyorum
        </button>
      </div>
      <div class="form-group col-md-4"></div>

    </div>

  </div>

  <ng-template #templateEdit>
    <div class="modal-header">
      <label class="title-modal">{{'bilgiGuncellestirmeAlani' | translate}}</label>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="editForm" (ngSubmit)="updateBagisYapanBilgileri()">
        <div class="row">
          <div class="form-group col-md-6">
            <label class="badge">{{'tckn' | translate}}</label>
            <input type="text" formControlName="tckn" class="form-control" maxlength="11"/>
          </div>

          <div class="form-group col-md-6">
            <label class="badge">{{'adi' | translate}}</label>

            <input type="text" formControlName="ad" class="form-control" maxlength="60"/>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-6">
            <label class="badge">{{'soyadi' | translate}}</label>
            <input type="text" formControlName="soyad" class="form-control" maxlength="60"/>
          </div>


          <div class="form-group col-md-6">
            <label class="badge">{{'adres' | translate}}</label>
            <input type="text" formControlName="adres" class="form-control" maxlength="255"/>
          </div>
        </div>


        <div class="row">
          <div class="form-group col-md-6">
            <label class="badge">{{'adresIl' | translate}}</label>
            <!--<input type="text" formControlName="adresIl" class="form-control" maxlength="30"/>-->
            <select (change)="populateCity($event.target)"
                    class="btn btn-outline-secondary dropdown-toggle form-control" formControlName="adresIl">
              <option [ngValue]="" disabled>{{adresIl}}</option>
              <option *ngFor="let s of ilListesi"  [value]="s.id">{{s.ad}}</option>
            </select>
          </div>

          <div class="form-group col-md-6">

            <label class="badge">{{'adresIlce' | translate}}</label>
            <!--<input type="text" formControlName="ilceAdi" class="form-control" maxlength="30"/>-->
            <select class="btn btn-outline-secondary dropdown-toggle form-control" formControlName="ilceAdi">
              <option [ngValue]=""  [value]="ilceAdi" disabled>{{ilceAdi}}</option>
              <option *ngFor="let c of ilceListesi" [value]="c.ad">{{c.ad}}</option>
            </select>

          </div>
        </div>


        <div class="row">
          <div class="form-group col-md-6">
            <label class="badge">{{'evTel' | translate}}</label>
            <input type="text" formControlName="evTel" type="text" prefix="0" mask="(000) 000 00 00"
                   [showMaskTyped]="true" class="form-control"/>
          </div>

          <div class="form-group col-md-6">
            <label class="badge">{{'cepTel' | translate}}</label>
            <input type="text" formControlName="cepTel" type="text" prefix="0" mask="(000) 000 00 00"
                   [showMaskTyped]="true" class="form-control"/>
          </div>
        </div>


        <div class="row">
          <div class="form-group col-md-6">
            <label class="badge">{{'isTel' | translate}}</label>
            <input type="text" formControlName="isTel" type="text" prefix="0" mask="(000) 000 00 00"
                   [showMaskTyped]="true" class="form-control"/>
          </div>

          <div class="form-group col-md-6">
            <label class="badge">{{'eposta' | translate}}</label>
            <input type="text" formControlName="email" class="form-control" maxlength="50"/>
          </div>
        </div>

        <!--HATA MESAJLARI ALANI-->
        <div class="row">
          <div class="form-group">

            <div *ngIf="f.ad.errors">
              <div style="color: red" *ngIf="f.ad.errors.required">&nbsp;&nbsp; * Adı alanı zorunludur!</div>
              <div style="color: red" *ngIf="f.ad.errors.pattern">&nbsp;&nbsp; * Adı alanına sadece harf girişi yapılabilir!</div>
            </div>
            <div *ngIf="f.soyad.errors">
              <div style="color: red" *ngIf="f.soyad.errors.required">&nbsp;&nbsp; * Soyadı alanı zorunludur!</div>
              <div style="color: red" *ngIf="f.soyad.errors.pattern">&nbsp;&nbsp; * Soyadı alanına sadece harf girişi yapılabilir!</div>
            </div>
            <div *ngIf="f.adres.errors">
              <div style="color: red" *ngIf="f.adres.errors.required">&nbsp;&nbsp; * Adres alanı zorunludur!</div>
            </div>
            <div *ngIf="f.evTel.errors">
              <div style="color: red" *ngIf="f.evTel.errors.pattern">&nbsp;&nbsp; * Geçerli bir Ev Telefonu girmelisiniz</div>
            </div>
            <div *ngIf="f.cepTel.errors">
              <div style="color: red" *ngIf="f.cepTel.errors.pattern">&nbsp;&nbsp; * Geçerli bir Cep Telefonu girmelisiniz</div>
            </div>
            <div *ngIf="f.isTel.errors">
              <div style="color: red" *ngIf="f.isTel.errors.pattern">&nbsp;&nbsp; * Geçerli bir İş Telefonu girmelisiniz</div>
            </div>
            <div *ngIf="f.email.errors">
              <div style="color: red" *ngIf="f.email.errors.pattern">&nbsp;&nbsp; * Geçerli bir e-posta adresi girmelisiniz</div>
            </div>

          </div>
        </div>
        <!--HATA MESAJLARI ALANI-->


        <div class="modal-footer">
          <button type="button" class="btn btn-info" (click)="closeAndResetModal()" data-toggle="tooltip"
                  data-placement="top" title="{{'vazgecToolTip' | translate}}">{{'kapat' | translate}}</button>
          <button (click)="showSpinner()" class="btn btn-success" [disabled]="!editForm.valid" data-toggle="tooltip"
                  data-placement="top" title="{{'guncelleToolTip' | translate}}">{{'guncelle' | translate}}</button>
        </div>


        <!-- States: <br>
         <select (change)="populateCity($event.target)" [(ngModel)]="selectedState" >
           <option value="">Select State</option>
           <option *ngFor="let s of ilListesi" [value]="s.id">{{s.ad}}</option>
         </select>
         <br>
         <br>
         City: <br>
         <select [(ngModel)]="selectedCity">
           <option value="">Select City</option>
           <option *ngFor="let c of ilceListesi" [value]="c.id">{{c.ad}}</option>
         </select>-->


      </form>

    </div>
  </ng-template>

</div>
