import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {BrowserModule} from "@angular/platform-browser";
import {BsModalRef, ModalModule} from "ngx-bootstrap/modal";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {ConfirmationComponent} from "./confirmation/confirmation.component";



@NgModule({
  declarations: [ConfirmationComponent],
  providers: [BsModalRef],
  entryComponents: [ConfirmationComponent],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ModalModule.forRoot(),
  ], exports: [
    ModalModule,
    ReactiveFormsModule,
    ConfirmationComponent,
    CommonModule,
    TranslateModule,
  ],
})
export class SharedModule { }
