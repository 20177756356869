// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const BAGIS_PATH = 'bagis/';
const YARDIM_PATH = 'yardim/';
const COMMON_PATH = 'common/';
const GUVENLIK_PATH = 'guvenlik/';

export const environment = {
  production: false,
  //canlı web adresi:https://tskmevbss.mehmetcik.org.tr/bas-bss/main

  //local:
  //API_BASE_PATH: "http://localhost:6002/bas/bss/",
  //Mehmetçik Vakfı Cenk Bey test ortamı:
  //API_BASE_PATH: "http://10.75.20.177:6002/bas/bss/",
  // test:
  //API_BASE_PATH: "http://192.168.10.225:6002/bas/bss/",
  //prod
  API_BASE_PATH: "https://tskmevbss.mehmetcik.org.tr:6002/bas/bss/",

  BAGIS: {
    BAGISCI_BILGILERI: BAGIS_PATH +"bagisci-bilgileri",
    BAGIS_BILGILERI: BAGIS_PATH +"bagis-bilgileri",
    YENI_BAGISCI_BILGILERI: BAGIS_PATH +"yeni-bagisci-bilgileri",
    MENKUL_BAGIS_BAGISCI_BILGILERI: BAGIS_PATH +"menkul-bagis-bilgileri",
    GMENKUL_BAGIS_BAGISCI_BILGILERI: BAGIS_PATH +"gmenkul-bagis-bilgileri",
    GMENKUL_BAGIS_DETAY_BAGISCI_BILGILERI: BAGIS_PATH +"gmenkul-detay-bagis-bilgileri",
    KURBAN_BAGIS_BAGISCI_BILGILERI: BAGIS_PATH +"kurban-bagis-bilgileri",
    ODUL_BELGE: BAGIS_PATH +"odul",

  },

  YARDIM: {
    YARDIM_ALAN_BILGILERI: YARDIM_PATH + "yardim-alan-bilgileri",
    YARDIM_DURUMU: YARDIM_PATH + "yardim-durumu",
    YARDIM_ALAN_KISI_VE_YAKIN_BILGILERI: YARDIM_PATH + "yardim-alan-kisi-ve-yakin-bilgileri",
    YARDIM_OLUM_VE_MALULIYET_BILGILERI: YARDIM_PATH + "yardim-olum-maluliyet-bilgileri",
    SUREKLI_YARDIM_BAKIM: YARDIM_PATH + "surekli-yardim-bakim",
    SUREKLI_YARDIM_BAKIM_OGRENIM: YARDIM_PATH + "surekli-yardim-bakim-ogrenim",
    YARDIM_COCUK_DOGUM_BILGISI: YARDIM_PATH + "yardim-cocuk-dogum-bilgisi",
    YARDIM_COCUK_OLUM_BILGISI: YARDIM_PATH + "yardim-cocuk-olum-bilgisi",
    YARDIM_MALUL_OLUM_BILGISI: YARDIM_PATH + "yardim-malul-olum-bilgisi",
    YARDIM_ANNELER_GUNU_BILGISI: YARDIM_PATH + "yardim-anneler-gunu-bilgisi",
    YARDIM_BABALAR_GUNU_BILGISI: YARDIM_PATH + "yardim-babalar-gunu-bilgisi",
    UPDATE_YARDIM_ALAN_BILGILERI: YARDIM_PATH + "update-yardim-alan-bilgisi",
    YARDIM_ALAN_UST_KAYIT_BILGILERI: YARDIM_PATH + "yardim-alan-ust-kayit-bilgisi",
    YARDIM_ALAN_KISI_BILGILERI: YARDIM_PATH + "yardim-alan-kisi-bigileri",

  },

  COMMON: {
    SIFRE_DEGISTIR: COMMON_PATH + "change-password",
    GET_IL: COMMON_PATH + "il",
    GET_ILCE: COMMON_PATH + "ilce",
    GET_ILCE_AD: COMMON_PATH + "ilceAd",
  },

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
