import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from "./pages/dashboard/dashboard.component";
import {YetkisizIstekComponent} from "./shared/yetkisiz-istek/yetkisiz-istek.component";
import {AppLayoutComponent} from "./layout/app-layout/app-layout.component";
import {NotFoundComponent} from "./shared/not-found/not-found.component";
import {environment} from "../environments/environment";
import {YardimAlanBilgileriComponent} from "./pages/yardim/yardim-alan-bilgileri/yardim-alan-bilgileri.component";
import {YardimDurumuComponent} from "./pages/yardim/yardim-durumu/yardim-durumu.component";
import {BagisBilgileriComponent} from "./pages/bagis/bagis-bilgileri/bagis-bilgileri.component";
import {SifreDegistirComponent} from "./pages/sifre-degistir/sifre-degistir.component";
import {AuthGuard} from "./security/auth.guard";
import {LoginComponent} from "./login/login.component";
import {MainComponent} from "./main/main.component";
import {BagisciBilgileriComponent} from "./pages/bagis/bagisci-bilgileri/bagisci-bilgileri.component";
import {RegisterComponent} from "./register/register.component";
import {SifreUnuttumComponent} from "./pages/sifre-unuttum/sifre-unuttum.component";

const routes: Routes = [
  {
    path: 'bas-bss', component: AppLayoutComponent, canActivate: [AuthGuard], children: [
      {path: 'bas-bss', pathMatch: 'full', redirectTo: 'dashboard'},
      {path: 'dashboard', component: DashboardComponent},
      {path: 'bas-bss/yetkisizIstek', component: YetkisizIstekComponent},
      {
        path: '',
        children: [
          {path: environment.YARDIM.YARDIM_ALAN_BILGILERI, component: YardimAlanBilgileriComponent},
          {path: environment.YARDIM.YARDIM_DURUMU, component: YardimDurumuComponent},
          {path: environment.BAGIS.BAGIS_BILGILERI, component: BagisBilgileriComponent},
          {path: environment.BAGIS.BAGISCI_BILGILERI, component: BagisciBilgileriComponent},
          {path: environment.COMMON.SIFRE_DEGISTIR, component: SifreDegistirComponent},
        ],
      },
    ]
  },
  {path: 'bas-bss/main', component: MainComponent},
  {path: 'bas-bss/login', component: LoginComponent},
  {path: 'bas-bss/register', component: RegisterComponent},
  {path: 'bas-bss/sifremiunuttum', component: SifreUnuttumComponent},
  {path: 'bas-bss/**', component: NotFoundComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
