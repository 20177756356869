<div  class="content-wrapper">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">Yardım Bilgileri</li>
      <!--<li class="breadcrumb-item" aria-current="page">{{'bagisBildirimleri'|translate}}</li>-->
    </ol>
  </nav>
  <div class="card text-center">
    <!--YARDIM ALAN MEHMETÇİK VE MEHMETÇİK YAKINI BİLGİLERİ-->
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <div class="card">

          <div class="card-body">

            <table class="tableGenelClass" cellspacing="10" cellpadding="10" width="100%">
              <tbody>
              <tr>
                <td class="Yardim" align="center" style="color: white;background-color: rgb(56,122,176)">
                  YARDIM ALAN MEHMETÇİK VE MEHMETÇİK YAKINI BİLGİLERİ
                </td>
              </tr>
              <tr>
                <td align="center">
                  <table cellspacing="0" cellpadding="4" rules="all"
                         class="dataGridYardimClass" border="1" id="dgYardimAlanYakin"
                         style="width:100%;border-collapse:collapse;">
                    <thead>
                    <th
                      *ngFor="let col of yardimAlanYakiniBilgileriCols;"
                      style="border: 1px solid #adb5bd; text-align: center;">{{col.name}}</th>
                    <th style="border: 1px solid #adb5bd; text-align: center;">İŞLEMLER</th>
                    </thead>
                    <tbody>
                    <tr *ngFor="let row of yardimAlanYakiniBilgileriRows;">
                      <td align="center"
                          *ngFor="let col of yardimAlanYakiniBilgileriCols;">
                        {{getValue(row[col.prop], col.prop)}}
                      </td>
                      <td align="center">
                        <button type="button" class="btn btn-primary" (click)="openModal(templateEdit,row)"
                                data-toggle="tooltip"
                                data-placement="top" title="Bilgileri Güncellemek İçin Tıklayınız">
                          <fa-icon [icon]="pencilIcon"></fa-icon>
                        </button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td height="20">
                </td>
              </tr>
              </tbody>
            </table>

          </div>

        </div>
      </div>
      <div class="col-md-1"></div>
    </div>

    <!--BİR KEREYE MAHSUS YAPILAN YARDIMLAR-->
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <div class="card">
          <div class="card-body">

            <table class="tableGenelClass" cellspacing="10" cellpadding="10" width="100%">
              <tbody>
              <tr>
                <td class="Yardim" align="center" style="color: white;background-color: rgb(56,122,176)">
                  BİR KEREYE MAHSUS YAPILAN YARDIMLAR
                </td>
              </tr>
              <tr>
                <td align="center">
                  <table cellspacing="0" cellpadding="4" rules="all"
                         class="dataGridYardimClass" border="1"
                         style="width:100%;border-collapse:collapse;">
                    <thead>
                    <th *ngFor="let col of birKereyeMahsusYardimlarCols;"
                        style="border: 1px solid #adb5bd; text-align: center;">{{col.name}}</th>
                    </thead>
                    <tbody>

                    <tr *ngFor="let row of birKereyeMahsusYardimlarRows;">
                      <td align="center" *ngFor="let col of birKereyeMahsusYardimlarCols;">
                        {{getValue(row[col.prop], col.prop)}}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td height="20">
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-1"></div>
    </div>

    <!--SÜREKLİ YARDIMLAR: BAKIM YARDIMI ( KENDİSİ )-->
    <div class="row" *ngIf="(!yardimBakimBilgileriRowNull)">
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <div class="card">
          <div class="card-body">

            <table class="table table-hover ">
              <tbody>
              <tr class="panel panel-default"
                  data-toggle="collapse"
                  [attr.data-target]="'#collapseKendisi'" (click)="collapse=!collapse">
                <td>
                  <div class="panel-group">
                    <div class="panel-heading">
                      <div class="row">
                        <div class="col-md-2" ></div>
                        <div class="col-md-8">SÜREKLİ YARDIMLAR: BAKIM YARDIMI ( KENDİSİ )</div>
                        <div class="col-md-2" >
                          <button type="button" class="btn btn-link" style="float: right;text-align: center; font-size: 12px; color: white">Detay için tıklayınız</button>
                        </div>
                      </div>
                    </div>
                    <div id="collapseKendisi" class="panel-collapse collapse">
                      <table cellspacing="0" cellpadding="4" rules="all"
                             class="dataGridYardimClass" border="1"
                             style="width:100%;border-collapse:collapse;">
                        <thead>
                        <th *ngFor="let col of yardimBakimOgrenimBilgileriCols;"
                            style="border: 1px solid #adb5bd; text-align: center;">{{col.name}}</th>
                        </thead>
                        <tbody>

                        <tr *ngFor="let row of yardimBakimBilgileriRow;">
                          <td align="center">
                            {{row["yil"]}}-{{row["donem"]}}
                          </td>
                          <td align="center">
                            {{row["tutar"] | currency:'':''}} {{row["dovizKodu"]}}
                          </td>
                        </tr>
                        </tbody>
                      </table>

                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-1"></div>
    </div>

    <!--SÜREKLİ YARDIMLAR: BAKIM ÖĞRENİM YARDIMI-->
    <div class="row" *ngIf="!yardimBakimOgrenimBilgileriListNull">
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <div class="card">
          <div class="card-body">

            <table class="table table-hover ">
              <tbody>
              <tr *ngFor="let listObj of yardimBakimOgrenimBilgileriList; let i = index" class="panel panel-default"
                  data-toggle="collapse"
                  [attr.data-target]="'#collapse' + i" (click)="collapse=!collapse">
                <td>
                  <div class="panel-group">
                    <div class="panel-heading">
                      <div class="row">
                        <div class="col-md-2"></div>
                        <div class="col-md-8">SÜREKLİ YARDIMLAR: BAKIM ÖĞRENİM YARDIMI ( {{listObj.adSoyad}} )</div>
                        <div class="col-md-2">
                          <button type="button" class="btn btn-link" style="float: right;text-align: center; font-size: 12px; color: white">Detay için tıklayınız</button>
                        </div>
                      </div>
                    </div>
                    <div id="collapse{{i}}" class="panel-collapse collapse">
                      <table cellspacing="0" cellpadding="4" rules="all"
                             class="dataGridYardimClass" border="1"
                             style="width:100%;border-collapse:collapse;">
                        <thead>
                        <th *ngFor="let col of yardimBakimOgrenimBilgileriCols;"
                            style="border: 1px solid #adb5bd; text-align: center;">{{col.name}}</th>
                        </thead>
                        <tbody>

                        <tr *ngFor="let row of listObj.yardimBakimBilgileriDto;">
                          <td align="center">
                            {{row["yil"]}}-{{row["donem"]}}
                          </td>
                          <td align="center">
                            {{row["tutar"] | currency:'':''}} {{row["dovizKodu"]}}
                          </td>
                        </tr>
                        </tbody>
                      </table>

                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-1"></div>
    </div>

    <!--ÇOCUK DOĞUM YARDIMI-->
    <div class="row" *ngIf="!yardimBilgisiCocukDogumRowNull">
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <div class="card">
          <div class="card-body">

            <table class="table table-hover ">
              <tbody>
              <tr class="panel panel-default"
                  data-toggle="collapse"
                  [attr.data-target]="'#collapseCocukDogum'" (click)="collapse=!collapse">
                <td>
                  <div class="panel-group">
                    <div class="panel-heading">

                      <div class="row">
                        <div class="col-md-2"></div>
                        <div class="col-md-8">ÇOCUK DOĞUM YARDIMI</div>
                        <div class="col-md-2">
                          <button type="button" class="btn btn-link" style="float: right;text-align: center; font-size: 12px; color: white">Detay için tıklayınız</button>
                        </div>
                      </div>

                    </div>
                    <div id="collapseCocukDogum" class="panel-collapse collapse">
                      <table cellspacing="0" cellpadding="4" rules="all"
                             class="dataGridYardimClass" border="1"
                             style="width:100%;border-collapse:collapse;">
                        <thead>
                        <th *ngFor="let col of yardimBilgisiCols;"
                            style="border: 1px solid #adb5bd; text-align: center;">{{col.name}}</th>
                        </thead>
                        <tbody>

                        <tr *ngFor="let row of yardimBilgisiCocukDogumRow;">
                          <td align="center">
                            {{row["ad"]}} {{row["soyad"]}}
                          </td>
                          <td align="center">
                            {{row["tutar"] | currency:'':''}} {{row["dovizKodu"]}}
                          </td>
                        </tr>
                        </tbody>
                      </table>

                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-1"></div>
    </div>

    <!--ÇOCUK ÖLUM YARDIMI-->
    <div class="row" *ngIf="!yardimBilgisiCocukOlumRowNull">
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <div class="card">
          <div class="card-body">

            <table class="table table-hover ">
              <tbody>
              <tr class="panel panel-default"
                  data-toggle="collapse"
                  [attr.data-target]="'#collapseCocukOlum'" (click)="collapse=!collapse">
                <td>
                  <div class="panel-group">
                    <div class="panel-heading">
                      <div class="row">
                        <div class="col-md-2"></div>
                        <div class="col-md-8">ÇOCUK ÖLÜM YARDIMI</div>
                        <div class="col-md-2">
                          <button type="button" class="btn btn-link" style="float: right;text-align: center; font-size: 12px; color: white">Detay için tıklayınız</button>
                        </div>
                      </div>

                    </div>
                    <div id="collapseCocukOlum" class="panel-collapse collapse">
                      <table cellspacing="0" cellpadding="4" rules="all"
                             class="dataGridYardimClass" border="1"
                             style="width:100%;border-collapse:collapse;">
                        <thead>
                        <th *ngFor="let col of yardimBilgisiCols;"
                            style="border: 1px solid #adb5bd; text-align: center;">{{col.name}}</th>
                        </thead>
                        <tbody>
                        <tr *ngFor="let row of yardimBilgisiCocukOlumRow;">
                          <td align="center">
                            {{row["ad"]}} {{row["soyad"]}}
                          </td>
                          <td align="center">
                            {{row["tutar"] | currency:'':''}} {{row["dovizKodu"]}}
                          </td>
                        </tr>
                        </tbody>
                      </table>

                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-1"></div>
    </div>

    <!--MALUL ÖLÜM YARDIMI-->
    <div class="row" *ngIf="!yardimBilgisiMalulOlumRowNull">
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <div class="card">
          <div class="card-body">

            <table class="table table-hover ">
              <tbody>
              <tr class="panel panel-default"
                  data-toggle="collapse"
                  [attr.data-target]="'#collapseMalulOlum'" (click)="collapse=!collapse">
                <td>
                  <div class="panel-group">
                    <div class="panel-heading">
                      <div class="row">
                        <div class="col-md-2"></div>
                        <div class="col-md-8">MALUL ÖLÜM YARDIMI</div>
                        <div class="col-md-2">
                          <button type="button" class="btn btn-link" style="float: right;text-align: center; font-size: 12px; color: white">Detay için tıklayınız</button>
                        </div>
                      </div>

                    </div>
                    <div id="collapseMalulOlum" class="panel-collapse collapse">
                      <table cellspacing="0" cellpadding="4" rules="all"
                             class="dataGridYardimClass" border="1"
                             style="width:100%;border-collapse:collapse;">
                        <thead>
                        <th *ngFor="let col of yardimBilgisiCols;"
                            style="border: 1px solid #adb5bd; text-align: center;">{{col.name}}</th>
                        </thead>
                        <tbody>
                        <tr *ngFor="let row of yardimBilgisiMalulOlumRow;">
                          <td align="center">
                            {{row["ad"]}} {{row["soyad"]}}
                          </td>
                          <td align="center">
                            {{row["tutar"] | currency:'':''}} {{row["dovizKodu"]}}
                          </td>
                        </tr>
                        </tbody>
                      </table>

                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-1"></div>
    </div>

    <!--ANNELER GÜNÜ YARDIMI-->
    <div class="row" *ngIf="!yardimAnnelerGunuRowNull">
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <div class="card">
          <div class="card-body">

            <table class="table table-hover ">
              <tbody>
              <tr class="panel panel-default"
                  data-toggle="collapse"
                  [attr.data-target]="'#collapseAnneler'" (click)="collapse=!collapse">
                <td>
                  <div class="panel-group">
                    <div class="panel-heading">
                      <div class="row">
                        <div class="col-md-2"></div>
                        <div class="col-md-8">ANNELER GÜNÜ YARDIMI</div>
                        <div class="col-md-2">
                          <button type="button" class="btn btn-link" style="float: right;text-align: center; font-size: 12px; color: white">Detay için tıklayınız</button>
                        </div>
                      </div>

                    </div>
                    <div id="collapseAnneler" class="panel-collapse collapse">
                      <table cellspacing="0" cellpadding="4" rules="all"
                             class="dataGridYardimClass" border="1"
                             style="width:100%;border-collapse:collapse;">
                        <thead>
                        <th *ngFor="let col of yardimBakimOgrenimBilgileriCols;"
                            style="border: 1px solid #adb5bd; text-align: center;">{{col.name}}</th>
                        </thead>
                        <tbody>

                        <tr *ngFor="let row of yardimAnnelerGunuRow;">
                          <td align="center">
                            {{row["yil"]}}-{{row["donem"]}}
                          </td>
                          <td align="center">
                            {{row["tutar"] | currency:'':''}} {{row["dovizKodu"]}}
                          </td>
                        </tr>
                        </tbody>
                      </table>

                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-1"></div>
    </div>

    <!--BABALAR GÜNÜ YARDIMI-->
    <div class="row" *ngIf="!yardimBabalarGunuRowNull">
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <div class="card">
          <div class="card-body">

            <table class="table table-hover ">
              <tbody>
              <tr class="panel panel-default"
                  data-toggle="collapse"
                  [attr.data-target]="'#collapseBabalar'" (click)="collapse=!collapse">
                <td>
                  <div class="panel-group">
                    <div class="panel-heading">
                      <div class="row">
                        <div class="col-md-2"></div>
                        <div class="col-md-8">BABALAR GÜNÜ YARDIMI</div>
                        <div class="col-md-2">
                          <button type="button" class="btn btn-link" style="float: right;text-align: center; font-size: 12px; color: white">Detay için tıklayınız</button>
                        </div>
                      </div>

                    </div>
                    <div id="collapseBabalar" class="panel-collapse collapse">
                      <table cellspacing="0" cellpadding="4" rules="all"
                             class="dataGridYardimClass" border="1"
                             style="width:100%;border-collapse:collapse;">
                        <thead>
                        <th *ngFor="let col of yardimBakimOgrenimBilgileriCols;"
                            style="border: 1px solid #adb5bd; text-align: center;">{{col.name}}</th>
                        </thead>
                        <tbody>

                        <tr *ngFor="let row of yardimBabalarGunuRow;">
                          <td align="center">
                            {{row["yil"]}}-{{row["donem"]}}
                          </td>
                          <td align="center">
                            {{row["tutar"] | currency:'':''}} {{row["dovizKodu"]}}
                          </td>
                        </tr>
                        </tbody>
                      </table>

                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-1"></div>
    </div>
  </div>

  <!--template modal içerisinde ilKod ve İlAd için alanların olduğu bir pop-updır-->
  <ng-template #templateEdit>
    <div class="modal-header">
      <label>BİLGİ GÜNCELLEŞTİRME ALANI</label>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="bilgiGuncellemeForm" (ngSubmit)="bilgiGuncelle()">
        <!--        <div class="row">-->
        <div class="card" style="margin-left: 10px">
          <div class="card-header">
            Kişisel Bilgiler
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label class="badge">TCKN</label>
                  <input type="text" (keypress)="keyPressNumbers($event)" [ngClass]="{ 'is-invalid': f.tckn.errors}" maxlength="11" formControlName="tckn"
                         class="form-control"/>

                </div>

              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="badge">Adı</label>
                  <input type="text" formControlName="adi" (input)="onUpdateChange($event,'adi')" [ngClass]="{ 'is-invalid': f.adi.errors}" maxlength="30"
                         class="form-control"/>
                </div>

              </div>


              <div class="col-md-4">
                <div class="form-group">
                  <label class="badge">Soyadı</label>
                  <input type="text" formControlName="soyAdi"  (input)="onUpdateChange($event,'soyAdi')" [ngClass]="{ 'is-invalid': f.soyAdi.errors}" maxlength="30" class="form-control"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label class="badge">Doğum tarihi</label>
                  <input type="date" formControlName="dogumTarihi" (input)="onUpdateChange($event,'dogumTarihi')" maxlength="30" class="form-control"/>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label
                    class="badge">{{bilgiGuncellemeForm.value.yakinlikDerecesi == "Kendisi" ? "Doğum Yeri" : "Okulu"}}</label>
                  <input type="text"
                         [formControlName]="bilgiGuncellemeForm.value.yakinlikDerecesi == 'Kendisi' ? 'dogumYeri' : 'okulAdi'"
                         class="form-control"/>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label
                    class="badge">{{bilgiGuncellemeForm.value.yakinlikDerecesi == "Kendisi" ? "Fotoğraf Yükle" : "Okul Sınıfı"}}</label>
                  <input type="text" formControlName="okulSinif" class="form-control"
                         *ngIf="bilgiGuncellemeForm.value.yakinlikDerecesi != 'Kendisi'"/>
                  <input type="file" name="file" id="fileKendisi" accept="image/*" (change)="changeListener($event)"
                         formControlName="photo" *ngIf="bilgiGuncellemeForm.value.yakinlikDerecesi == 'Kendisi'"/>
                </div>
              </div>
            </div>
            <!--HATA MESAJLARI ALANI-->
            <div class="row">
              <div class="form-group">
                <div *ngIf="f.tckn.errors">
                  <div style="color: red" *ngIf="f.tckn.errors.required">&nbsp;&nbsp; * TCKN alanı zorunludur!</div>
                </div>
                <div *ngIf="f.adi.errors">
                <div style="color: red" *ngIf="f.adi.errors.required">&nbsp;&nbsp; * Adı alanı zorunludur!</div>
                <div style="color: red" *ngIf="f.adi.errors.pattern">&nbsp;&nbsp; * Adı alanına sadece harf girişi yapılabilir!</div>
              </div>
                <div *ngIf="f.soyAdi.errors">
                  <div style="color: red" *ngIf="f.soyAdi.errors.required">&nbsp;&nbsp; * Soyadı alanı zorunludur!</div>
                  <div style="color: red" *ngIf="f.soyAdi.errors.pattern">&nbsp;&nbsp; * Soyadı alanına sadece harf girişi yapılabilir!</div>

                </div>
                <div *ngIf="f.eposta.errors">
                  <div style="color: red" *ngIf="f.eposta.errors.pattern">&nbsp;&nbsp; * Geçerli bir e-posta adresi girmelisiniz</div>
                </div>
              </div>
            </div>
            <!--HATA MESAJLARI ALANI-->
            <div class="row" *ngIf="bilgiGuncellemeForm.value.yakinlikDerecesi != 'Kendisi'">
              <div class="col-md-4">
                <div class="form-group">
                  <label class="badge">Fotoğraf yükle</label>
                  <input type="file" accept="image/*" (change)="changeListener($event)">
                  <!-- <input type="image" name="file" id="file" formControlName="photo"/>-->
                </div>
              </div>
              <div class="col-md-4">
              </div>
              <div class="col-md-4">
              </div>
            </div>

          </div>

        </div>
        <!--        </div>
                <div class="row">-->
        <hr/>
        <div class="card" style="margin-left: 10px">
          <div class="card-header">
            Adres Bilgileri
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="badge">Adres</label>
                  <textarea name="txtAdres" id="txtAdres" maxlength="250"
                            formControlName="adresi" (input)="onUpdateChange($event,'adresi')" class="form-control"></textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label class="badge">İl</label>
                  <!--<input type="text" formControlName="il" class="form-control"/>-->
                  <select (change)="populateCity($event.target)" (input)="onUpdateChange($event,'il')"
                          class="btn btn-outline-secondary dropdown-toggle form-control" formControlName="il">
                    <option [ngValue]="" disabled>{{il}}</option>
                    <option *ngFor="let s of ilListesi" [value]="s.ad">{{s.ad}}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="badge">İlçe</label>
                  <!--<input type="text" formControlName="ilce" class="form-control"/>-->
                  <select class="btn btn-outline-secondary dropdown-toggle form-control" (input)="onUpdateChange($event,'ilce')" formControlName="ilce">
                    <option [ngValue]="" [value]="ilce" disabled>{{ilce}}</option>
                    <option *ngFor="let c of ilceListesi" [value]="c.ad">{{c.ad}}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="badge">Mahalle</label>
                  <input type="text" formControlName="mahalle" (input)="onUpdateChange($event,'mahalle')" class="form-control"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label class="badge">Cadde - Sokak</label>
                  <input type="text" formControlName="caddeSokak" (input)="onUpdateChange($event,'caddeSokak')" class="form-control"/>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="badge">Bina - Site Adı</label>
                  <input type="text" formControlName="binaSiteAdi" (input)="onUpdateChange($event,'binaSiteAdi')" class="form-control"/>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="badge">Bina - Blok Adı</label>
                  <input type="text" formControlName="binaBlokAdi"  (input)="onUpdateChange($event,'binaBlokAdi')" class="form-control"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label class="badge">Dış Kapı No</label>
                  <input type="text" formControlName="disKapiNo" (input)="onUpdateChange($event,'disKapiNo')" class="form-control"/>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="badge">İç Kapı No</label>
                  <input type="text" formControlName="icKapiNo"  (input)="onUpdateChange($event,'icKapiNo')"class="form-control"/>
                </div>
              </div>
              <div class="col-md-4">
              </div>
            </div>

          </div>

        </div>

        <hr/>
        <div class="card" style="margin-left: 10px">
          <div class="card-header">
            İletişim Bilgileri
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label class="badge">Ev telefonu 1</label>
                  <input type="text" formControlName="evNo1" (input)="onUpdateChange($event,'evNo1')"  type="text" prefix="0" mask="(000) 000 00 00"
                         [showMaskTyped]="true" class="form-control"/>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="badge">Ev telefonu 2</label>
                  <input type="text" formControlName="evNo2" (input)="onUpdateChange($event,'evNo2')" type="text" prefix="0" mask="(000) 000 00 00"
                         [showMaskTyped]="true" class="form-control"/>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="badge">Cep telefonu 1</label>
                  <input type="text" formControlName="cepNo1" (input)="onUpdateChange($event,'cepNo1')" type="text" prefix="0" mask="(000) 000 00 00"
                         [showMaskTyped]="true" class="form-control"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label class="badge">Cep telefonu 2</label>
                  <input type="text" formControlName="cepNo2"  (input)="onUpdateChange($event,'cepNo2')" type="text" prefix="0" mask="(000) 000 00 00"
                         [showMaskTyped]="true" class="form-control"/>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="badge">Cep telefonu 3</label>
                  <input type="text" formControlName="cepNo3" (input)="onUpdateChange($event,'cepNo3')"  type="text" prefix="0" mask="(000) 000 00 00"
                         [showMaskTyped]="true" class="form-control"/>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="badge">İş telefonu</label>
                  <input type="text" formControlName="isNo" (input)="onUpdateChange($event,'isNo')"  type="text" prefix="0" mask="(000) 000 00 00"
                         [showMaskTyped]="true" class="form-control"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label class="badge">E-posta adresi</label>
                  <input type="text" formControlName="eposta" (input)="onUpdateChange($event,'eposta')"  class="form-control"/>
                </div>
              </div>
              <div class="col-md-4">
              </div>

            </div>
            <!--HATA MESAJLARI ALANI-->
            <div class="row">
              <div class="form-group">
                <div *ngIf="f.eposta.errors">
                  <div style="color: red" *ngIf="f.eposta.errors.pattern">&nbsp;&nbsp; * Geçerli bir e-posta adresi girmelisiniz</div>
                </div>
              </div>
              <div class="form-group">
                <div *ngIf="f.evNo1.errors">
                  <div style="color: red" *ngIf="f.evNo1.errors.pattern">&nbsp;&nbsp; * Geçerli bir Ev telefonu 1 girmelisiniz</div>
                </div>
              </div>
              <div class="form-group">
                <div *ngIf="f.evNo2.errors">
                  <div style="color: red" *ngIf="f.evNo2.errors.pattern">&nbsp;&nbsp; * Geçerli bir Ev telefonu 2 girmelisiniz</div>
                </div>
              </div>
              <div class="form-group">
                <div *ngIf="f.cepNo1.errors">
                  <div style="color: red" *ngIf="f.cepNo1.errors.pattern">&nbsp;&nbsp; * Geçerli bir Cep telefonu 1 girmelisiniz</div>
                </div>
              </div>
              <div class="form-group">
                <div *ngIf="f.cepNo2.errors">
                  <div style="color: red" *ngIf="f.cepNo2.errors.pattern">&nbsp;&nbsp; * Geçerli bir Cep telefonu 2 girmelisiniz</div>
                </div>
              </div>
              <div class="form-group">
                <div *ngIf="f.cepNo3.errors">
                  <div style="color: red" *ngIf="f.cepNo3.errors.pattern">&nbsp;&nbsp; * Geçerli bir Cep telefonu 3 girmelisiniz</div>
                </div>
              </div>
              <div class="form-group">
                <div *ngIf="f.isNo.errors">
                  <div style="color: red" *ngIf="f.isNo.errors.pattern">&nbsp;&nbsp; * Geçerli bir İş telefonu girmelisiniz</div>
                </div>
              </div>
            </div>
            <!--HATA MESAJLARI ALANI-->

          </div>

        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-info" (click)="closeAndResetModal()" data-toggle="tooltip"
                  data-placement="top" title="{{'vazgecToolTip' | translate}}">{{'kapat' | translate}}</button>
          <button type="submit" class="btn btn-success" [disabled]="!bilgiGuncellemeForm.valid" data-toggle="tooltip"
                  data-placement="top" title="{{'guncelleToolTip' | translate}}">{{'guncelle' | translate}}</button>
        </div>
      </form>

    </div>
  </ng-template>
</div>
