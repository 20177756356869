import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {Injectable} from "@angular/core";

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {

  rolList = Array<{ rolId: number, rolAd: string, url: string }>();
  rolListTemp = Array<{ rolId: number, rolAd: string, url: string }>();

  constructor(private router: Router) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (localStorage.getItem('currentUser')) {
      /*let currentUser = JSON.parse(<string>localStorage.getItem('currentUser'));
      if (state.url != '/yetkisizIstek' && state.url != '/dashboard' && !this.filterData(state.url)) {
        this.router.navigate(['/yetkisizIstek']);
        return false;
      }*/
      return true;
    }
    this.router.navigate(['/bas-bss/main']);
    return false;
  }

  filterData(locationName: any) {
    let permissions = JSON.parse(<string>localStorage.getItem('permissions'));
    this.rolList = permissions;
    this.rolListTemp = this.rolList.filter(object => {
      return object['url'].toString().includes(locationName.toString());
    });
    if (this.rolListTemp.length != 0) {
      return this.rolListTemp[0].url == locationName;
    } else return false;

  }
}
