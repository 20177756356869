import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {ApiService} from "./api.service";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class SifreService {
  private PATH =environment.COMMON
  private PATH_SIFRE_DEGISTIR =environment.COMMON.SIFRE_DEGISTIR
  private PATH_SIFRE_SIFIRLA ='token/resetPassword'
  constructor(private apiService:ApiService) { }


  changePassword(kullanici: any): Observable<any> {
    return this.apiService.post(this.PATH_SIFRE_DEGISTIR, kullanici).pipe(
      res => {
        if (res) {
          return res;
        } else {
          console.log(res);
          return res;
        }
      }
    );
  }


  resetPassword(kullanici: any): Observable<any> {
    return this.apiService.post( this.PATH_SIFRE_SIFIRLA, kullanici).pipe(
      res => {
        if (res) {
          return res;
        } else {
          console.log(res);
          return res;
        }
      }
    );
  }
}
