<section class="wrapper">
  <app-header></app-header>
  <app-sidebar></app-sidebar>
  <div class="content-area" id="main-container">

    <div class="page-wrapper">


      <router-outlet></router-outlet>

    </div>
  </div>
  <app-footer></app-footer>
</section>


