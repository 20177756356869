import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {faGlobe, faGlobeEurope, faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import {BagisciService} from "../../services/bagisci.service";
import {YardimAlanBilgileriService} from "../../services/yardim-alan-bilgileri.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  languageIcon = faGlobe
  languageEIcon = faGlobeEurope
  signOutIcon = faSignOutAlt
  pushRightClass: string = 'push-right';
  collapseClass: string = 'collapsed';
  isCollapsed = false;
  adSoyad: string = '';
tckn: any ='';
  activeUser = {};
  kisiBilgisi: any;
  private currentToken: any;

  constructor(public router: Router,
              private translateService: TranslateService,
              private bagisciService: BagisciService,
              private yardimAlanBilgileriService: YardimAlanBilgileriService,) {
    this.router.events.subscribe(val => {
      if (
        val instanceof NavigationEnd &&
        window.innerWidth <= 992 &&
        this.isToggled()
      ) {
        this.toggleSidebar();
      }
    });
  }

  ngOnInit(): void {
    this.langTurkce()
    let currentUser = JSON.parse(<string>localStorage.getItem('currentUser'));
    if (currentUser != null){
      this.tckn=currentUser.tckn;
      this.currentToken = currentUser.token;
      this.kisiBilgisi = {
        tckn: this.tckn,
        currentToken:this.currentToken

      }
      if (currentUser.kullaniciTipi == 1) {//Bağış Yapan
        this.getAllDataBagisYapan(this.kisiBilgisi)
      } else if (currentUser.kullaniciTipi == 2) {//Yardım Alan
        this.getAllDataYardimAlan(this.kisiBilgisi)
      }
    }




  }

  isToggled(): boolean {
    const dom: HTMLElement | null = document.querySelector('aside');
    return (dom) ? dom.classList.contains(this.collapseClass) : false;
  }

  toggleSidebar() {
    const dom: any = document.querySelector('aside');
    (dom) ? dom.classList.toggle(this.collapseClass) : '';
    const cdom: any = document.querySelector('#main-container');
    (cdom) ? cdom.classList.toggle(this.collapseClass) : '';
  }

  langTurkce() {
    this.translateService.use('tr')
  }

  langEng() {
    this.translateService.use('en')
  }


  getAllDataBagisYapan(kisiBilgisi: any) {
    this.bagisciService.getAll(kisiBilgisi).subscribe(
      response => {
        if (response.ad && response.soyad) {
          this.adSoyad = response.ad + ' ' + response.soyad;
        }
      }, error => {
        console.log(error)
      }
    )
  }

  getAllDataYardimAlan(kisiBilgisi: any) {
      this.yardimAlanBilgileriService.getKisiBilgisi(kisiBilgisi).subscribe(
      response => {
        if (response.ad && response.soyad) {
          this.adSoyad = response.ad + ' ' + response.soyad;
        }
      }, error => {
         console.log(error)
      }
    )
  }
}
