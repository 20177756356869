import {Component, OnInit, TemplateRef} from '@angular/core';
import {NotificationService} from "../../../services/notification.service";
import {NgxSpinnerService} from "ngx-spinner";
import {DomSanitizer} from "@angular/platform-browser";
import {FormGroup} from "@angular/forms";
import {BagisciService} from "../../../services/bagisci.service";
import {DatePipe} from "@angular/common";
import {NgbCarouselConfig} from "@ng-bootstrap/ng-bootstrap";
import {VERSION} from "@angular/core";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-bagis-bilgileri',
  templateUrl: './bagis-bilgileri.component.html',
  styleUrls: ['./bagis-bilgileri.component.scss'],
  providers: [NgbCarouselConfig]
})
export class BagisBilgileriComponent implements OnInit {
  images = [944, 1011, 984].map((n) => `https://picsum.photos/id/${n}/900/500`);


  pipe = new DatePipe('tr-TR'); // Use your own locale

  modalRef: BsModalRef | any;
  gmenkulDetayForm: FormGroup | any;
  bagisTarihi: any;
  bagisTutari: any;
  menkulForm: FormGroup | any;
  colsMenkulBagisBilgileri: [] | any;
  rowsMenkulBagisBilgileri: [] | any;
  rowsGMenkulBagisBilgileri: [] | any;
  rowsGMenkulDetayBagisBilgileri: [] | any;
  rowsKurbanBagisBilgileri: [] | any;
  rowsOdulBilgileri: [] | any;

  menkulNull=false;
  gmenkulNull=false;
  kurbanNull=false;
  odulNull=false;
  //imageSource: [] | any
  imageSource: Array<{ src: any, adres: any, ilAd: any, ilce: any, mevkii: any, odaSayisi: any, metrekare: any, insaatTarihi: any, kat: any, raicDeger: any, raicTarihi: any, ozellikleri: any, kod: any }> = [];
  image: any
  tckn: any;
  name = 'Angular ' + VERSION.major;
  currentToken:any;
  kisiBilgisi:any;

  constructor(private notifyService: NotificationService,
              private spinner: NgxSpinnerService,
              private bagisciServis: BagisciService,
              private datePipe: DatePipe,
              private modalService: BsModalService,
              private sanitizer: DomSanitizer,
              config: NgbCarouselConfig) {

    config.interval = 200000;//Resim değişme süresi
    config.keyboard = true;
    config.pauseOnHover = true;


    let currentUser = JSON.parse(<string>localStorage.getItem('currentUser'));
    this.tckn = currentUser.tckn
    this.currentToken = currentUser.token

    this.kisiBilgisi = {
      tckn: this.tckn,
      currentToken:this.currentToken
    }

    this.getData()
  }

  ngOnInit(): void {
    this.showSpinner()

  }


  send() {

  }



  getData() {

    this.kisiBilgisi = {
      tckn: this.tckn,
      currentToken:this.currentToken
    }


    this.bagisciServis.getmenkulBagisciBilgileri(this.kisiBilgisi).subscribe(
      response => {
        this.rowsMenkulBagisBilgileri = response;
        if(!response.length){
          this.menkulNull=true
        }
        this.spinner.hide();
      }, error => {
        this.notifyService.translateNotification(error.error, 'hata')
        this.spinner.hide();

      }
    )

    this.bagisciServis.getOdulBilgileri(this.kisiBilgisi).subscribe(
      response => {
        this.rowsOdulBilgileri = response;
        if(!response.length){
          this.odulNull=true
        }
        this.spinner.hide();

      }, error => {
        this.notifyService.translateNotification(error.error, 'hata')
        this.spinner.hide();

      }
    )

    this.bagisciServis.getGMenkulBagisciBilgileri(this.kisiBilgisi).subscribe(
      response => {
        this.rowsGMenkulBagisBilgileri = response;
        if(!response.length){
          this.gmenkulNull=true
        }
      }, error => {
        this.notifyService.translateNotification(error.error, 'hata')
        this.spinner.hide();

      }
    )

    this.bagisciServis.getGMenkulDetayBagisciBilgileri(this.kisiBilgisi).subscribe(
      response => {
        this.rowsGMenkulDetayBagisBilgileri = response;
        this.rowsGMenkulDetayBagisBilgileri.forEach((key: any) => {

          /* console.log("foreach içi");
           console.log(key);
           console.log(this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64,` + key.bagisciResim));*/
          let deneme = {
            src: this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64,` + key.gayrimenkulResim),
            adres: key.adres,
            ilAd: key.ilAd,
            ilce: key.ilce,
            ozellikleri: key.ozellikleri,
            raicDeger: key.raicDeger,
            raicTarihi: key.raicTarihi,
            odaSayisi: key.odaSayisi,
            metrekare: key.metrekare,
            insaatTarihi: key.insaatTarihi,
            kat: key.kat,
            mevkii: key.mevkii,
            kod: key.kod

          };

          this.imageSource.push(deneme);


        });
        this.spinner.hide();

      }, error => {
        this.notifyService.translateNotification(error.error, 'hata')
        this.spinner.hide();

      }
    )


    this.bagisciServis.getkurbanBagisciBilgileri(this.kisiBilgisi).subscribe(
      response => {
        this.rowsKurbanBagisBilgileri = response;
        if(!response.length){
          this.kurbanNull=true
        }
        this.spinner.hide();

      }, error => {
        this.notifyService.translateNotification(error.error, 'hata')
        this.spinner.hide();

      }
    )
  }

  showSpinner() {
    this.spinner.show();
  }


  showModal(templateDetay: TemplateRef<any>) {
    /*this.modalRef = this.modalService.show(templateDetay);*/
    this.modalRef = this.modalService.show(templateDetay, {class: 'modal-lg'});
  }
}
