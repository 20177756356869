<aside class="sidebar" [ngClass]="{collapseClass: isActive}">
  <nav class="navbar navbar-dark" style="background-color: #387AB0 !important; color: white !important;">
    <a class="navbar-brand m-6 py-60" href="bas-bss/dashboard"></a>
    <a class="navbar-brand m-10 py-20"  href="bas-bss/dashboard">{{'anaMenu' | translate}}&nbsp;&nbsp;&nbsp;&nbsp;</a>
    <span></span>
    <a class="navbar-brand py-6 material-icons toggle-sidebar" (click)="toggleSidebar()" data-toggle="tooltip"
       data-placement="top" title="{{sidebarToolTip}}">menu</a>
  </nav>

  <nav class="navigation">
    <ul>


      <li [hidden]="(bagisciHidden)">
      <a routerLink="{{environment.BAGIS.BAGISCI_BILGILERI}}" routerLinkActive="active">
          <span><span class="nav-icon material-icons">list</span> {{'bagisciBilgileri' |translate}} </span>
        </a>
      </li>
      <li [hidden]="(bagisciHidden)" >
      <a routerLink="{{environment.BAGIS.BAGIS_BILGILERI}}" routerLinkActive="active">
          <span><span class="nav-icon material-icons">list</span> {{'bagisBilgileri' |translate}} </span>
        </a>
      </li>
      <li [hidden]="(yardimAlanHidden)" >
        <a routerLink="{{environment.YARDIM.YARDIM_ALAN_BILGILERI}}" routerLinkActive="active" data-toggle="tooltip"
           data-placement="top" title="{{'yardimaEsasMehmetcikToolTip' | translate}}" >
          <span><span class="nav-icon material-icons">list</span> {{'mehmetcikBilgileri'|translate}}</span>
        </a>
      </li>
      <li [hidden]="(yardimAlanHidden)">
      <a routerLink="{{environment.YARDIM.YARDIM_DURUMU}}" routerLinkActive="active">
          <span><span class="nav-icon material-icons">list</span> {{'yardimBilgileri'|translate}}</span>
        </a>
      </li>
      <li>
        <a routerLink="{{environment.COMMON.SIFRE_DEGISTIR}}" routerLinkActive="active">
          <span><span class="nav-icon material-icons">list</span> {{'sifreDegistirme'|translate}}</span>
        </a>
      </li>

    </ul>


  </nav>
</aside>
