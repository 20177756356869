import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthenticationService} from "../security/authentication.service";
import {NotificationService} from "../services/notification.service";
import {NgxSpinnerService} from "ngx-spinner";
import {IConfig} from "ngx-mask";
import {CommonService} from "../services/common.service";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup | any;
  loading = false;
  submitted = false;
  kullaniciTipi: number | any;
  error = '';
  currentYear: any =(new Date()).getFullYear();

  @ViewChild('tplBekleyiniz', {static: true}) tplBekleyiniz !: TemplateRef<any>

  /**Counter*/
  intervalId = 0;
  message = '';
  seconds = 6;
  modalRef: BsModalRef | any;



  constructor(private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private modalService: BsModalService,
              private router: Router,
              private authenticationService: AuthenticationService,
              private notifyService: NotificationService,
              private spinner: NgxSpinnerService) {
    this.route.queryParams.subscribe(params => {
      this.kullaniciTipi = params['kullaniciTipi'];
      if (this.kullaniciTipi == null) {
        this.router.navigate(['/bas-bss/main']);
      }
    });


  }

  ngOnInit(): void {

    this.registerForm = this.formBuilder.group({
      "tckimlikNo": [null, [Validators.minLength(10), Validators.required]],
      "kullaniciTipi": [this.kullaniciTipi],
      "ad": [null, [Validators.required,]],
      "soyad": [null, [Validators.required]],
      "babaAdi": [null],
      "email": [null, [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      "adres": [null, [Validators.required]],
      "ilce": [null, [Validators.required]],
      "il": [null, [Validators.required]],
      "evTel": [null,[ Validators.pattern('.{8,}$')]],
      "isTel": [null,[ Validators.pattern('.{8,}$')]],
      "cepTel": [null,[ Validators.pattern('.{8,}$')]],
    });


    const maskConfigFunction: () => Partial<IConfig> = () => {
      return {
        validation: false,
      };
    };
  }


  get f() {
    return this.registerForm.controls;
  }

  keyPressNumbers(event: { which: any; keyCode: any; preventDefault: () => void; }) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  register() {


    if (!this.registerForm.valid) {
      this.submitted = true;
      return;
    }
    this.spinner.show();
    // stop here if form is invalid
        if (this.registerForm.invalid) {
      this.spinner.hide();
      return;
    } else {
      this.loading = true;
      this.authenticationService.register(this.registerForm.getRawValue()).pipe().subscribe(
        response => {

          this.notifyService.translateNotification('Geçici şifreniz E-posta adresinize gönderilmiştir..\n', 'basarili')
          this.spinner.hide();
          this.showModal();
          this.start()
          //this.registerForm.reset();
        },
        error => {
          this.notifyService.translateNotification(error.error, 'hata')
          this.loading = false;
          this.spinner.hide();
        }
      )

    }
  }

  /**Counter **/

  clearTimer() {
    clearInterval(this.intervalId);
  }

  start() {
    this.countDown();
  }

  stop() {
    this.clearTimer();
    this.message = ``;
  }

  private countDown() {
    this.clearTimer();
    this.intervalId = window.setInterval(() => {
      this.seconds -= 1;
      if (this.seconds === 0) {
        this.router.navigate(['/bas-bss/login'],{ queryParams: { kullaniciTipi: this.kullaniciTipi } });
        this.modalService.hide();// Modal kapatılır
        this.message = 'Sayaç sıfırlandı!';
        this.clearTimer()
      } else {
        if (this.seconds < 0) {
          this.seconds = 5;
        } // reset
        this.message = `${this.seconds} saniye sonra Giriş Sayfasına yönlendirileceksiniz.`;


      }
    }, 1000);
  }


  showModal() {
    /*this.modalRef = this.modalService.show(templateDetay);*/
    this.modalRef = this.modalService.show(this.tplBekleyiniz, {class: 'modal-md'});
  }

}
