import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {ApiService} from "./api.service";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private PATH_IL = environment.COMMON.GET_IL
  private PATH_ILCE = environment.COMMON.GET_ILCE
  private PATH_ILCE_AD = environment.COMMON.GET_ILCE_AD


  constructor(private apiService: ApiService)  {}

    getIl(): Observable<any> {
      return this.apiService.get(this.PATH_IL).pipe(map(
        res => {
          if (res) {
            return res;
          } else {
            console.log('get All fault' + res);
            return {}
          }
        }
      ));
    }


  getIlce(ilId: any): Observable<any> {
    return this.apiService.post(this.PATH_ILCE, ilId).pipe(map(
      res => {
        if (res) {
          return res;
        } else {
          console.log('get All fault' + res);
          return {}
        }
      }
    ));
  }

  getIlceIlAd(ilAd: any): Observable<any> {

    return this.apiService.post(this.PATH_ILCE_AD, ilAd).pipe(map(
      res => {
        if (res) {
          return res;
        } else {
          console.log('get All fault' + res);
          return {}
        }
      }
    ));
  }
}
