import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthenticationService} from "../security/authentication.service";
import {NotificationService} from "../services/notification.service";
import {SifreService} from "../services/sifre.service";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {environment} from "../../environments/environment";
import {RecaptchaErrorParameters} from "ng-recaptcha";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup | any;
  loading = false;
  submitted = false;
  returnUrl: string | any;
  error = '';
  kullaniciTipi: number | any;
  modalRef: BsModalRef | any;
  fieldTextType: boolean | any;
  eyeIcon = faEye;
  captchaResolved = false;
  captchaSiteKey = "";


  constructor(private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private sifreService: SifreService,
              private modalService: BsModalService,
              private authenticationService: AuthenticationService,
              private notifyService: NotificationService) {
    this.route.queryParams.subscribe(params => {
      this.kullaniciTipi = params['kullaniciTipi'];
      if (this.kullaniciTipi == null) {
        this.router.navigate(['/bas-bss/main']);
      }
    });
  }

  ngOnInit() {

    //Google captcha ayarları link üzerinden yapılır:https://www.google.com/recaptcha/admin/site/471587474
    //kullanıcı adı:mehmetcikvakfi1982@gmail.com
    //Şifre:Mhmtckvkf82

    /*console.log(`API_BASE_PATH:`, environment.API_BASE_PATH);
    if (environment.API_BASE_PATH == "http://localhost:6002/bas/bss/") {
      this.captchaSiteKey = "6LeH3RscAAAAABgGZhEo38idfitP47pOzL0TrF3T";
    } else if (environment.API_BASE_PATH == "http://10.75.20.177:6002/bas/bss/") {
      this.captchaSiteKey = "6LelXhwcAAAAANFdDo1XQ21NkkAMolAlXEO3wBvR";
    } else if (environment.API_BASE_PATH == "https://tskmevbss.mehmetcik.org.tr:6002/bas/bss/") {
      this.captchaSiteKey = "6LfnXBwcAAAAAO86hTWqjzoKfAjt_RRtt2_DPQ1O";
    }*/

    this.captchaSiteKey = "6LfnXBwcAAAAAO86hTWqjzoKfAjt_RRtt2_DPQ1O";
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],
      password: ['', [Validators.required]],
      captcha: ['', Validators.required]

    });

    // reset login status
    this.authenticationService.logout();

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/bas-bss/dashboard';
  }

  // convenience getter for easy access to form fields
  currentYear: any = (new Date()).getFullYear();
  sifremiUnuttumForm: FormGroup | any;

  @ViewChild('templateSifremiUnuttum', {static: true}) templateSifremiUnuttum !: TemplateRef<any>

  get f() {
    return this.loginForm.controls;
  }

  login() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.login(this.f.username.value, this.f.password.value, this.kullaniciTipi)
      .pipe()
      .subscribe(
        data => {
          if (data.sifreAktifmi == true) {
            this.router.navigate([this.returnUrl]);
          } else {
            this.router.navigate(['/bas-bss/common/change-password']);
          }
        },
        error => {
          this.notifyService.translateNotification(error.error, 'hata')
          this.loading = false;
        });
  }

  keyPressNumbers(event: { which: any; keyCode: any; preventDefault: () => void; }) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  validateInput(field: any) {

    this.loginForm.patchValue({[field.username]: this.loginForm.controls[field.username].value});
  }


  sendSifreSifirla() {

    this.sifreService.resetPassword(this.sifremiUnuttumForm.value).subscribe(
      response => {
        this.closeAndResetModal();
        this.notifyService.translateNotification('Geçici şifre bilgileriniz, ' + response.email + ' mail adresine gönderilmiştir.', 'basarili')
      },
      error => {
        this.notifyService.translateNotification(error.error, 'hata')
      }
    )

  }

  openModal(template: TemplateRef<any>) {
    this.sifremiUnuttumForm = this.formBuilder.group({
      "tckimlikNo": [{
        value: null,
        disabled: false
      }, [Validators.required, Validators.maxLength(11)]],
      "kullaniciTipi": this.kullaniciTipi
    })
    this.modalRef = this.modalService.show(template, {class: 'modal-md'});
  }

  closeAndResetModal() {
    this.modalRef.hide();
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  public resolved(captchaResponse: string): void {
   // console.log(`Resolved captcha with response: ${captchaResponse}`);
    this.captchaResolved = true;
  }

  public onError(errorDetails: RecaptchaErrorParameters): void {
   // console.log(`reCAPTCHA error encountered; details:`, errorDetails);
    this.captchaResolved = false;


  }
}
