import {Component, OnInit} from '@angular/core';
import {faQuestion} from "@fortawesome/free-solid-svg-icons";
import {NavigationEnd, Router} from "@angular/router";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  questionIcon = faQuestion;

  environment = environment;
  isActive: boolean = false;
  showMenu: string = '';
  pushRightClass: string = 'push-right';
  collapseClass: string = 'collapsed';
  rolList = Array<{ rolId: number, rolAd: string, url: string }>();
  rolListTemp = Array<{ rolId: number, rolAd: string, url: string }>();

  bagisciHidden: boolean=true;
  yardimAlanHidden: boolean=true;
  kullaniciTipi: string | null = "0";
  sifreAktifmi: boolean=false;
  check: boolean;
  sidebarToolTip: string="Daralt";

  constructor(public router: Router) {
    /*kullaniciTipi == 1 -> Bağış Yapan
    kullaniciTipi == 2 -> Yardım Alan*/
    let currentUser = JSON.parse(<string>localStorage.getItem('currentUser'));
    this.kullaniciTipi = currentUser.kullaniciTipi;
    if (this.kullaniciTipi == "1") {
      if (currentUser.sifreAktifmi) {
        this.bagisciHidden = false;
      }
      this.yardimAlanHidden = true;
    }else if (this.kullaniciTipi == "2") {
      this.bagisciHidden = true;
      if (currentUser.sifreAktifmi) {
        this.yardimAlanHidden = false;
      }
    }else {
      this.bagisciHidden = true;
      this.yardimAlanHidden = true;
    }


    this.router.events.subscribe(val => {
      if (
        val instanceof NavigationEnd &&
        window.innerWidth <= 992 &&
        this.isToggled()
      ) {
        this.toggleSidebar();
      }
    });
    this.showMenu = 'dashboard';
    this.check=true;
  }


  eventCalled() {
    this.isActive = !this.isActive;
  }

  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }

  isToggled(): boolean {
    const dom: HTMLElement | null = document.querySelector('aside');
    return (dom) ? dom.classList.contains(this.collapseClass) : false;
  }

  toggleSidebar() {
    const dom: any = document.querySelector('aside');
    (dom) ? dom.classList.toggle(this.collapseClass) : '';
    const cdom: any = document.querySelector('#main-container');
    (cdom) ? cdom.classList.toggle(this.collapseClass) : '';
    if(!this.check){this.sidebarToolTip="Daralt"; this.check=true}else{this.sidebarToolTip="Genişlet";this.check=false}
  }

  ngOnInit(): void {
    /* let currentUser = JSON.parse(<string>localStorage.getItem('currentUser'));

     this.kullaniciTanimlamaService.getPermittedRoles(currentUser.userName).subscribe(res => {
       this.rolList = res;
     })
     console.log(this.rolList)*/

  }

  filterData(locationName: any) {
    this.rolListTemp = this.rolList.filter(object => {
      return object['url'].toString().includes(locationName.toString());
    });
    if (this.rolListTemp.length != 0) {
      if (this.rolListTemp[0].url == locationName) {
        return true;
      } else return false;
    } else return false;

  }

}
