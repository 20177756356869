import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {YardimAlanBilgileriService} from "../../../services/yardim-alan-bilgileri.service";
import {NotificationService} from "../../../services/notification.service";
import {NgxSpinnerService} from "ngx-spinner";
import {CurrencyPipe, DatePipe} from "@angular/common";
import {faChevronDown, faChevronUp, faPencilAlt, faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import {YakinlikDerecesiEnum} from "../../../common/Enum";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {DomSanitizer} from "@angular/platform-browser";
import {CommonService} from "../../../services/common.service";

@Component({
    selector: 'app-yardim-durumu',
    templateUrl: './yardim-durumu.component.html',
    styleUrls: ['./yardim-durumu.component.scss']
})
export class YardimDurumuComponent implements OnInit {


    tckn: any;

    yardimAlanYakiniBilgileriCols: [] | any;
    yardimAlanYakiniBilgileriRows: [] | any;

    birKereyeMahsusYardimlarCols: [] | any;
    birKereyeMahsusYardimlarRows: [] | any;

    yardimBakimOgrenimBilgileriCols: [] | any;
    yardimBakimOgrenimBilgileriList: [] | any;

    yardimBakimBilgileriRow: [] | any;
    yardimAnnelerGunuRow: [] | any;
    yardimBabalarGunuRow: [] | any;

    yardimBilgisiCols: [] | any;
    yardimBilgisiCocukDogumRow: [] | any;
    yardimBilgisiCocukOlumRow: [] | any;
    yardimBilgisiMalulOlumRow: [] | any;

    keys = Object.keys;

    collapseIcon = faChevronUp
    expandIcon = faChevronDown
    pencilIcon = faPencilAlt

    collapse: boolean = false;
    yakinlikDerecesiEnum: any;
    files: any[];

    ilBilgisi: any;
    il: any;
    ilce: any;
    ilListesi: any = [];
    ilceListesi: any = [];

    islemlerOK = false;

    yardimBakimBilgileriRowNull = false;
    yardimBabalarGunuRowNull = false;
    yardimAnnelerGunuRowNull = false;
    yardimBilgisiMalulOlumRowNull = false;
    yardimBilgisiCocukOlumRowNull = false;
    yardimBilgisiCocukDogumRowNull = false;
    yardimBakimOgrenimBilgileriListNull = false;

  currentToken:any;
  kisiBilgisi:any;

    @ViewChild('tplBilgiGuncellemeCell', {static: true}) tplBilgiGuncellemeCell !: TemplateRef<any>
    bilgiGuncellemeForm: FormGroup | any;
    modalRef: BsModalRef | any;
    ilAdi: any;

    adUpdate: any;    soyAdiUpdate: any;    dogumTarihiUpdate: any;    ilceUpdate: any;    mahalleUpdate: any;    ilUpdate: any;    epostaUpdate: any;
    isNoUpdate: any;    cepNo3Update: any;    cepNo2Updatete: any;    cepNo1Update: any;    evNo2Updatepdate: any;    evNo1Update: any;    icKapiNoUpdate: any;
    disKapiNoUpdate: any;    binaBlokAdiUpdate: any;    binaSiteAdiUpdate: any;    caddeSokakUpdate: any;    cepNo2Update: any;    evNo2Update: any
    adresi: any; dogumYeri: any;

    constructor(private yardimAlanBilgileriService: YardimAlanBilgileriService,
                private notifyService: NotificationService,
                private spinner: NgxSpinnerService,
                private datePipe: DatePipe,
                private currencyPipe: CurrencyPipe,
                private formBuilder: FormBuilder,
                private modalService: BsModalService,
                private commonService: CommonService) {

        let currentUser = JSON.parse(<string>localStorage.getItem('currentUser'));
        //console.log(currentUser)
        this.tckn = currentUser.tckn
      this.currentToken = currentUser.token

        //console.log(this.tckn)
        this.files = [];

      this.kisiBilgisi = {
        tckn: this.tckn,
        currentToken:this.currentToken
      }

    }

    timeOut() {
        setTimeout(() => {

            this.spinner.hide()
        }, 10000);

    }

    ngOnInit(): void {
        //this.showSpinner()
        //this.timeOut();
        this.getData()
        this.yardimAlanYakiniBilgileriCols = [
            {prop: 'adi', name: 'ADI'},
            {prop: 'soyAdi', name: 'SOYADI'},
            {prop: 'dogumTarihi', name: 'DOĞUM TARİHİ', pipe: this.datePipe},
            {prop: 'yakinlikDerecesi', name: 'YAKINLIK DERECESİ'}
        ];

        this.birKereyeMahsusYardimlarCols = [
            {prop: 'ad', name: 'ADI'},
            {prop: 'soyAd', name: 'SOYADI'},
            {prop: 'yakinlikDerecesi', name: 'YAKINLIK DERECESİ'},
            {prop: 'yardimAdi', name: 'YARDIM ADI'},
            {prop: 'yardimTarihi', name: 'Tarihi', pipe: this.datePipe},
            {prop: 'yardimMiktari', name: 'Tutarı'}
        ];

        this.yardimBakimOgrenimBilgileriCols = [
            {prop: 'yil', name: 'DÖNEMİ'},
            {prop: 'tutar', name: 'TUTARI'}
        ];

        this.yardimBilgisiCols = [
            {prop: 'ad', name: 'ADI'},
            {prop: 'tutar', name: 'TUTARI'}
        ];
        this.yakinlikDerecesiEnum = YakinlikDerecesiEnum;

        this.getIlListesi();

    }

    getData() {


        this.yardimAlanBilgileriService.getYardimAlanYakiniBilgileri(this.kisiBilgisi).subscribe(
            response => {
                this.yardimAlanYakiniBilgileriRows = response;
            }, error => {
                this.notifyService.translateNotification(error.error, 'hata')
            }
        )
        this.yardimAlanBilgileriService.getYardimOlumVeMaluliyetBilgileri(this.kisiBilgisi).subscribe(
            response => {
                this.birKereyeMahsusYardimlarRows = response;
            }, error => {
                this.notifyService.translateNotification(error.error, 'hata')
            }
        )
        this.yardimAlanBilgileriService.getSurekliYardimBakimOgrenimBilgileri(this.kisiBilgisi).subscribe(
            response => {
                this.yardimBakimOgrenimBilgileriList = response;
                if (!response.length) {
                    this.yardimBakimOgrenimBilgileriListNull = true
                }
            }, error => {
                this.notifyService.translateNotification(error.error, 'hata')
            }
        )

        this.yardimAlanBilgileriService.getSurekliYardimBakimBilgileri(this.kisiBilgisi).subscribe(
            response => {
                this.yardimBakimBilgileriRow = response;
                if (!response.length) {
                    this.yardimBakimBilgileriRowNull = true
                }
            }, error => {
                this.notifyService.translateNotification(error.error, 'hata')
            }
        )

        this.yardimAlanBilgileriService.getYardimCocukDogumBilgileri(this.kisiBilgisi).subscribe(
            response => {
                this.yardimBilgisiCocukDogumRow = response;
                if (!response.length) {
                    this.yardimBilgisiCocukDogumRowNull = true
                }
            }, error => {
                this.notifyService.translateNotification(error.error, 'hata')
            }
        )

        this.yardimAlanBilgileriService.getYardimCocukOlumBilgileri(this.kisiBilgisi).subscribe(
            response => {
                this.yardimBilgisiCocukOlumRow = response;
                if (!response.length) {
                    this.yardimBilgisiCocukOlumRowNull = true
                }
            }, error => {
                this.notifyService.translateNotification(error.error, 'hata')
            }
        )
        this.yardimAlanBilgileriService.getYardimMalulOlumBilgileri(this.kisiBilgisi).subscribe(
            response => {
                this.yardimBilgisiMalulOlumRow = response;
                if (!response.length) {
                    this.yardimBilgisiMalulOlumRowNull = true
                }
            }, error => {
                this.notifyService.translateNotification(error.error, 'hata')
            }
        )
        this.yardimAlanBilgileriService.getYardimAnnelerGunuBilgileri(this.kisiBilgisi).subscribe(
            response => {
                this.yardimAnnelerGunuRow = response;
                if (!response.length) {
                    this.yardimAnnelerGunuRowNull = true
                }
            }, error => {
                this.notifyService.translateNotification(error.error, 'hata')
            }
        )
        this.yardimAlanBilgileriService.getYardimBabalarGunuBilgileri(this.kisiBilgisi).subscribe(
            response => {
                this.yardimBabalarGunuRow = response;
                if (!response.length) {
                    this.yardimBabalarGunuRowNull = true
                }
            }, error => {
                this.notifyService.translateNotification(error.error, 'hata')
            }
        )
    }

    getValue(value: any, colProp: any) {
        if (colProp == 'dogumTarihi' || colProp == 'yardimTarihi') {
            return this.datePipe.transform(value, 'dd.MM.yyyy');
        }
        if (colProp == 'yakinlikDerecesi') {
            let yakinlikDerecesi;
            Object.keys(this.yakinlikDerecesiEnum).forEach(key => {
                if (value == this.yakinlikDerecesiEnum[key].variable) {
                    yakinlikDerecesi = this.yakinlikDerecesiEnum[key].display
                }
            })
            return yakinlikDerecesi;
        }
        if (colProp == 'yardimMiktari') {
            return this.currencyPipe.transform(value, '', '') + ' TL';
        }
        return value;

    }

    openModal(template: TemplateRef<any>, row: any) {
        this.bilgiGuncellemeForm = this.formBuilder.group({
            "tckn": [row.tckn, [Validators.required, Validators.minLength(11), Validators.maxLength(11), Validators.pattern("^[0-9]*$")]],
            "yakinlikDerecesi": [{value: this.getValue(row.yakinlikDerecesi, "yakinlikDerecesi"), disabled: false}],
            "kayitNo": [{value: row.kayitNo, disabled: false}],
            "adi": [row.adi, [Validators.required, Validators.pattern('^[A-Za-ziİüÜöÖşŞğĞçÇı ]+$')]],
            "soyAdi": [row.soyAdi, [Validators.required, Validators.pattern('^[A-Za-ziİüÜöÖşŞğĞçÇı ]+$')]],
            "dogumTarihi": [{value: this.datePipe.transform(row.dogumTarihi, 'yyyy-MM-dd'), disabled: false}],
            "dogumYeri": [{value: row.dogumYeri, disabled: true}],
            "okulAdi": [{value: row.okulAdi, disabled: true}],
            "okulSinif": [{value: row.okulSinif, disabled: true}],

            "il": [{value: row.il, disabled: false}],
            "ilce": [{value: row.ilce, disabled: false}],
            "adresi": [{value: row.adresi, disabled: true}],
            "mahalle": [{value: null, disabled: false}],
            "caddeSokak": [{value: null, disabled: false}],
            "binaSiteAdi": [{value: null, disabled: false}],
            "binaBlokAdi": [{value: null, disabled: false}],
            "disKapiNo": [{value: null, disabled: false}],
            "icKapiNo": [{value: null, disabled: false}],

            "evNo1": [row.evNo1, [Validators.pattern('.{8,}$')]],
            "evNo2": [row.evNo2, [Validators.pattern('.{8,}$')]],
            "cepNo1": [row.cepNo1, [Validators.pattern('.{8,}$')]],
            "cepNo2": [row.cepNo2, [Validators.pattern('.{8,}$')]],
            "cepNo3": [row.cepNo3, [Validators.pattern('.{8,}$')]],
            "isNo": [row.isNo, [Validators.pattern('.{8,}$')]],
            "eposta": [row.eposta, [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
            "photo": [{value: null, disabled: false}]
        });
        this.modalRef = this.modalService.show(template, {class: 'modal-lg'});
        this.il = row.il;
        this.ilce = row.ilce;
        this.adresi=row.adresi;
        this.dogumYeri=row.dogumYeri;
    }

    /*onFileChanged(event: any) {
      this.files = event.target.files;
    }

    onUpload() {
      const formData = new FormData();
      for (const file of this.files) {
        formData.append("deneme", file, file.name);
      }
      /!*this.http.post('url', formData).subscribe(x => ....);*!/
    }*/

    changeListener(event: any): void {
        this.readThis(event.target);
    }

    readThis(inputValue: any): void {
        var file: File = inputValue.files[0];
        var myReader: FileReader = new FileReader();

        myReader.onloadend = (e) => {
            this.bilgiGuncellemeForm.value.photo = myReader.result;

        }
        myReader.readAsDataURL(file);
    }

    bilgiGuncelle() {
        if (!this.bilgiGuncellemeForm.valid)
            return;
        /**Değişiklik yapılan alanları kırmızı renk'e çevirir**/
        this.bilgiGuncellemeForm.value.adi = this.adUpdate != null ? this.adUpdate : this.bilgiGuncellemeForm.value.adi;
        this.bilgiGuncellemeForm.value.soyAdi = this.soyAdiUpdate != null ? this.soyAdiUpdate : this.bilgiGuncellemeForm.value.soyAdi;
        this.bilgiGuncellemeForm.value.dogumTarihi = this.dogumTarihiUpdate != null ? this.dogumTarihiUpdate : this.bilgiGuncellemeForm.value.dogumTarihi;
        this.bilgiGuncellemeForm.value.ilce = this.ilceUpdate != null ? this.ilceUpdate : this.bilgiGuncellemeForm.value.ilce;
        this.bilgiGuncellemeForm.value.il = this.ilUpdate != null ? this.ilUpdate : this.bilgiGuncellemeForm.value.il;
        this.bilgiGuncellemeForm.value.mahalle = this.mahalleUpdate != null ? this.mahalleUpdate : this.bilgiGuncellemeForm.value.mahalle;
        this.bilgiGuncellemeForm.value.caddeSokak = this.caddeSokakUpdate != null ? this.caddeSokakUpdate : this.bilgiGuncellemeForm.value.caddeSokak;
        this.bilgiGuncellemeForm.value.binaSiteAdi = this.binaSiteAdiUpdate != null ? this.binaSiteAdiUpdate : this.bilgiGuncellemeForm.value.binaSiteAdi;
        this.bilgiGuncellemeForm.value.binaBlokAdi = this.binaBlokAdiUpdate != null ? this.binaBlokAdiUpdate : this.bilgiGuncellemeForm.value.binaBlokAdi;
        this.bilgiGuncellemeForm.value.disKapiNo = this.disKapiNoUpdate != null ? this.disKapiNoUpdate : this.bilgiGuncellemeForm.value.disKapiNo;
        this.bilgiGuncellemeForm.value.icKapiNo = this.icKapiNoUpdate != null ? this.icKapiNoUpdate : this.bilgiGuncellemeForm.value.icKapiNo;
        this.bilgiGuncellemeForm.value.evNo1 = this.evNo1Update != null ? this.evNo1Update : this.bilgiGuncellemeForm.value.evNo1;
        this.bilgiGuncellemeForm.value.evNo2 = this.evNo2Update != null ? this.evNo2Update : this.bilgiGuncellemeForm.value.evNo2;
        this.bilgiGuncellemeForm.value.cepNo1 = this.cepNo1Update != null ? this.cepNo1Update : this.bilgiGuncellemeForm.value.cepNo1;
        this.bilgiGuncellemeForm.value.cepNo2 = this.cepNo2Update != null ? this.cepNo2Update : this.bilgiGuncellemeForm.value.cepNo2;
        this.bilgiGuncellemeForm.value.cepNo3 = this.cepNo3Update != null ? this.cepNo3Update : this.bilgiGuncellemeForm.value.cepNo3;
        this.bilgiGuncellemeForm.value.isNo = this.isNoUpdate != null ? this.isNoUpdate : this.bilgiGuncellemeForm.value.isNo;
        this.bilgiGuncellemeForm.value.eposta = this.epostaUpdate != null ? this.epostaUpdate : this.bilgiGuncellemeForm.value.eposta;
        this.bilgiGuncellemeForm.value.adresi = this.adresi;//adresi guncelleme kapalı olduğu için if eklenmedi
        this.bilgiGuncellemeForm.value.dogumYeri = this.dogumYeri;//adresi guncelleme kapalı olduğu için if eklenmedi

        /*****/
        /*this.yardimAlanBilgileriService.updateYardimAlanBilgileri(this.bilgiGuncellemeForm.getRawValue()).subscribe(*/
        this.yardimAlanBilgileriService.updateYardimAlanBilgileri(this.bilgiGuncellemeForm.value).subscribe(
            response => {


                this.notifyService.translateNotification('Bilgi güncelleştirme talebiniz alınmıştır. Gerekli kontroller yapıldıktan sonra güncelleştirme işlemi gerçekleştirilecektir.', 'basarili')
                this.modalRef.hide();

            }, error => {
                this.modalRef.hide();
                this.notifyService.translateNotification(error.error, 'hata')
            }
        )

    }

    closeAndResetModal() {
        this.modalRef.hide();
        this.bilgiGuncellemeForm.reset();
    }

    populateCity(value: any) {
        this.ilBilgisi = {
            ilAd: value.value
        }
        this.ilceListesi = this.getIlce(this.ilBilgisi);

    }


    getIlListesi() {
        this.commonService.getIl().subscribe(
            response => {
                this.ilListesi = response;
            }, error => {
                this.notifyService.translateNotification(error.error, 'hata')
            }
        )

    }


    getIlce(ilId: any) {

        this.commonService.getIlceIlAd(ilId).subscribe(
            response => {
                this.ilceListesi = response;

            }, error => {
                this.notifyService.translateNotification(error.error, 'hata')
            }
        )

    }

    showSpinner() {
        this.spinner.show();
    }

    hideSpinner() {
        this.spinner.hide();
    }

    keyPressNumbers(event: { which: any; keyCode: any; preventDefault: () => void; }) {
        var charCode = (event.which) ? event.which : event.keyCode;
        // Only Numbers 0-9
        if ((charCode < 48 || charCode > 57)) {
            event.preventDefault();
            return false;
        } else {
            return true;
        }
    }

    get f() {
        return this.bilgiGuncellemeForm.controls;
    }

    onUpdateChange(updateValue: any, fieldName: any) {
        if (fieldName == "adi") {        this.adUpdate = "<b style=\"color:red\">" + updateValue.target.value + "</b>"}
        else if (fieldName == "soyAdi") {this.soyAdiUpdate = "<b style=\"color:red\">" + updateValue.target.value + "</b>"}
        //else if (fieldName == "dogumTarihi") { this.dogumTarihiUpdate = "<b style=\"color:red\">" + updateValue.target.value + "</b>"}
        //tarih formatında olduğu için font eklenemedi başka çözüm yaz !!!
        else if (fieldName == "ilce") {  this.ilceUpdate = "<b style=\"color:red\">" + updateValue.target.value + "</b>"}
        else if (fieldName == "il") {  this.ilUpdate = "<b style=\"color:red\">" + updateValue.target.value + "</b>"}
        else if (fieldName == "mahalle") {  this.mahalleUpdate = "<b style=\"color:red\">" + updateValue.target.value + "</b>"}
        else if (fieldName == "caddeSokak") {  this.caddeSokakUpdate = "<b style=\"color:red\">" + updateValue.target.value + "</b>"}
        else if (fieldName == "binaSiteAdi") {  this.binaSiteAdiUpdate = "<b style=\"color:red\">" + updateValue.target.value + "</b>"}
        else if (fieldName == "binaBlokAdi") {  this.binaBlokAdiUpdate = "<b style=\"color:red\">" + updateValue.target.value + "</b>"}
        else if (fieldName == "disKapiNo") {  this.disKapiNoUpdate = "<b style=\"color:red\">" + updateValue.target.value + "</b>"}
        else if (fieldName == "icKapiNo") {  this.icKapiNoUpdate = "<b style=\"color:red\">" + updateValue.target.value + "</b>"}
        else if (fieldName == "evNo1") {  this.evNo1Update = "<b style=\"color:red\">" + updateValue.target.value + "</b>"}
        else if (fieldName == "evNo2") {  this.evNo2Update = "<b style=\"color:red\">" + updateValue.target.value + "</b>"}
        else if (fieldName == "cepNo1") {  this.cepNo1Update = "<b style=\"color:red\">" + updateValue.target.value + "</b>"}
        else if (fieldName == "cepNo2") {  this.cepNo2Update = "<b style=\"color:red\">" + updateValue.target.value + "</b>"}
        else if (fieldName == "cepNo3") {  this.cepNo3Update = "<b style=\"color:red\">" + updateValue.target.value + "</b>"}
        else if (fieldName == "isNo") {  this.isNoUpdate = "<b style=\"color:red\">" + updateValue.target.value + "</b>"}
        else if (fieldName == "eposta") {  this.epostaUpdate = "<b style=\"color:red\">" + updateValue.target.value + "</b>"}

    }
}
