<app-header></app-header>
<div class="page-wrapper" style="text-align:center; vertical-align:top;">
  <div align="center">
    <div style="BACKGROUND-COLOR: white; text-align:center; vertical-align:top" height="100%"
         *ngIf="kullaniciTipi == 1">
      <br>
      <div id="pnlUserTypeDefinedBagisYapan">

        <div>
          <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6">
													<span id="lblTitleBagisYapan" style="color:Black;font-family:Arial;font-size:17px; ">Yaptığınız bağışları
                                                    sorgulayabilmek için bireysel sorgulama ve denetim sistemine kayıt olmanız gerekmektedir.</span>
            </div>
            <div class="col-md-3"></div>
          </div>

          <br>

          <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6">
              <a id="hlnkNewUserBagisYapan" href="/bas-bss/register?kullaniciTipi=1"
                 style="color:Blue;font-size:100%;"><u>Sisteme kayıt olmak için tıklayınız</u></a>
            </div>
            <div class="col-md-3"></div>
          </div>

          <br>

          <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6">
              <span id="lblTitleBagisYapan2"
                    style="color:Black;font-family:'Arial Black';font-size:17px; ">BAĞIŞ YAPAN KULLANICILAR İÇİN GİRİŞ SAYFASI</span><br>
            </div>
            <div class="col-md-3"></div>
          </div>

          <br>


          <!--// Şifre giriş formu-->
          <form [formGroup]="loginForm" (ngSubmit)="login()">
            <div class="row">
              <div class="col-md-4"></div>
              <div class="col-md-2">
                <div class="row">
                  <div class="col-md-4"></div>
                  <div class="col-md-8"><label><a style="color:Red; font-family:Arial;font-size:25px; ">*</a><b>T.C.
                    Kimlik No.</b></label></div>
                </div>
              </div>
              <div class="col-md-2" align="left">
                <input id="tcKimlikNo" type="text" formControlName="username" class="form-control"
                       (keypress)="keyPressNumbers($event)"
                       maxlength="11" [ngClass]="{ 'is-invalid': submitted && f.username.errors }"/>

              </div>
              <div class="col-md-4"></div>
            </div>

            <br>

            <div class="row">
              <div class="col-md-4"></div>
              <div class="col-md-2">
                <div class="row">
                  <div class="col-md-4"></div>
                  <div class="col-md-8"><label><b>Şifre</b></label></div>
                </div>
              </div>
              <div class="col-md-2" align="left">
                <div class="form-group">
                  <div class="input-group">

                    <input [type]="fieldTextType ? 'text' : 'password'" formControlName="password"
                           minlength="8" maxlength="20" class="form-control"
                           autocomplete="off"
                           [ngClass]="{'is-invalid': f.password.errors }"/>
                    <div class="input-group-append">
                        <span class="input-group-text">
                          <i (click)="toggleFieldTextType()">
                            <fa-icon [icon]="eyeIcon" data-toggle="tooltip" data-placement="top"
                                     title="{{'gosterGizle' | translate}}"></fa-icon></i>
                        </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4"></div>

            </div>


            <div class="row">

              <div class="col-md-6"></div>
              <div class="col-md-2">
                <re-captcha formControlName="captcha" size="compact" (resolved)="resolved($event)"
                            siteKey={{captchaSiteKey}} (error)="onError($event)"
                            errorMode="handled"></re-captcha>
                <div *ngIf="!captchaResolved && submitted" style="color: red">
                  {{'validcaptcha' | translate}}!
                </div>
              </div>
              <div class="col-md-4"></div>
            </div>


            <br>

            <!--//Giriş-->
            <div class="row">
              <div class="col-md-6"></div>
              <div class="col-md-2">
                <button [disabled]="loading" type="submit" class="btn btn-primary btn-block"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="{{'girisToolTip' | translate}}">{{'giris' | translate}}</button>
              </div>
              <div class="col-md-4"></div>
            </div>

            <br>

            <!--//Şifremi Unuttum-->
            <div class="row">
              <div class="col-md-6"></div>
              <div class="col-md-2">
                <button type="button" [disabled]="loading" (click)="openModal(templateSifremiUnuttum)"
                        class="btn btn-link btn-block"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="{{'sifremiUnuttumToolTip' | translate}}">{{'sifremiUnuttum' | translate}}</button>
              </div>
              <div class="col-md-4"></div>
            </div>

            <p></p>


            <!--//Hataların basıldığı alan-->
            <div class="row">
              <div class="col-md-6"></div>
              <div class="col-md-2">
                <div *ngIf="submitted && f.username.errors" style="color: red">
                  <div *ngIf="f.username.errors.required">{{'validTCKimlik' | translate}}!</div>
                </div>
              </div>
              <div class="col-md-4"></div>
            </div>

            <div class="row">
              <div class="col-md-6"></div>
              <div class="col-md-2">
                <div *ngIf="submitted && f.password.errors" style="color: red">
                  <div *ngIf="f.password.errors.required">{{'validSifreGiriniz' | translate}}!</div>
                </div>
              </div>
              <div class="col-md-4"></div>
            </div>


          </form>


        </div>
      </div>


      <br>
      <br>

      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-1" align="right"><font color="#ff0000" size="5">&nbsp;&nbsp;*</font></div>
        <div class="col-md-6">

          <p style="TEXT-ALIGN: justify"><font size="2">Bu bölüme;<br>
            <span id="lblAciklama_BagisYapan">
															-&nbsp;Bağışçılarımız için 11 haneli <b>T.C. kimlik numarasının</b>,<br>
															-&nbsp;Vergi mükellefi olan kurum ve kuruluşlar için <b>vergi kimlik numarası</b> (başına
															99 getirilerek)'nın,<br>
															-&nbsp;Vergi mükellefi olmayan kurum ve kuruluşlar için ise TSK Mehmetçik Vakfı
															Genel Müdürlüğü'nden temin edilecek <b>özel numaranın</b> yazılması
															gerekmektedir.</span>
          </font></p>

        </div>
        <div class="col-md-3"></div>

      </div>
      <br>
      <br>

      <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-4">
          <a id="hlUyelikTuruDegistirBagisYapan" [routerLink]="['/bas-bss/main']"
             style="color:Blue;font-size:100%;"><u>Ana Sayfa'ya
            dönmek için tıklayınız</u></a>
        </div>
        <div class="col-md-4"></div>
      </div>

    </div>


  </div>
</div>


<p></p>


<!--**********************************YARDIM ALANNN*************************************-->
<div class="page-wrapper" style="text-align:center; vertical-align:top;">
  <div align="center">
    <div style="BACKGROUND-COLOR: white; text-align:center; vertical-align:top" height="100%"
         *ngIf="kullaniciTipi == 2">
      <br>
      <div id="pnlUserTypeDefined">

        <div>
          <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6">
													<span id="lblTitleYardimAlan" style="color:Black;font-family:Arial;font-size:17px; ">Aldığınız yardımları
                                            sorgulayabilmek için bireysel sorgulama ve denetim sistemine kayıt olmanız
                                            gerekmektedir.</span>
            </div>
            <div class="col-md-3"></div>
          </div>

          <br>

          <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6">
              <a id="hlnkNewUserYardimAlan" href="/bas-bss/register?kullaniciTipi=2"
                 style="color:Blue;font-size:100%;"><u>Sisteme kayıt olmak için tıklayınız</u></a>
            </div>
            <div class="col-md-3"></div>
          </div>

          <br>

          <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6">
              <span id="lblTitleYardimAlan2"
                    style="color:Black;font-family:'Arial Black';font-size:17px; ">YARDIM ALAN KULLANICILAR İÇİN GİRİŞ SAYFASI</span><br>
            </div>
            <div class="col-md-3"></div>
          </div>

          <br>

          <!--// Şifre giriş formu-->
          <form [formGroup]="loginForm" (ngSubmit)="login()">
            <div class="row">
              <div class="col-md-4"></div>
              <div class="col-md-2">
                <div class="row">
                  <div class="col-md-4"></div>
                  <div class="col-md-8"><label><a style="color:Red; font-family:Arial;font-size:25px; ">*</a><b>T.C.
                    Kimlik No.</b></label></div>
                </div>
              </div>
              <div class="col-md-2" align="left">
                <input id="tcKimlikNoYardimAlan" type="text" formControlName="username" class="form-control"
                       (keypress)="keyPressNumbers($event)"
                       maxlength="11" [ngClass]="{ 'is-invalid': submitted && f.username.errors }"/>

              </div>
              <div class="col-md-4"></div>
            </div>

            <br>

            <div class="row">
              <div class="col-md-4"></div>
              <div class="col-md-2">
                <div class="row">
                  <div class="col-md-4"></div>
                  <div class="col-md-8"><label><b>Şifre</b></label></div>
                </div>
              </div>
              <div class="col-md-2" align="left">
                <div class="form-group">
                  <div class="input-group">

                    <input [type]="fieldTextType ? 'text' : 'password'" formControlName="password"
                           minlength="8" maxlength="20" class="form-control"
                           autocomplete="off"
                           [ngClass]="{'is-invalid': f.password.errors }"/>
                    <div class="input-group-append">
                        <span class="input-group-text">
                          <i (click)="toggleFieldTextType()">
                            <fa-icon [icon]="eyeIcon" data-toggle="tooltip" data-placement="top"
                                     title="{{'gosterGizle' | translate}}"></fa-icon></i>
                        </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4"></div>

            </div>

            <div class="row">

              <div class="col-md-6"></div>
              <div class="col-md-2">
                <re-captcha formControlName="captcha" size="compact" (resolved)="resolved($event)"
                            siteKey={{captchaSiteKey}} (error)="onError($event)"
                            errorMode="handled"></re-captcha>
                <div *ngIf="!captchaResolved && submitted" style="color: red">
                  {{'validcaptcha' | translate}}!
                </div>
              </div>
              <div class="col-md-4"></div>
            </div>

            <br>

            <!--//Giriş-->
            <div class="row">
              <div class="col-md-6"></div>
              <div class="col-md-2">
                <button [disabled]="loading" type="submit" class="btn btn-primary btn-block"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="{{'girisToolTip' | translate}}">{{'giris' | translate}}</button>
              </div>
              <div class="col-md-4"></div>
            </div>

            <br>

            <!--//Şifremi Unuttum-->
            <div class="row">
              <div class="col-md-6"></div>
              <div class="col-md-2">
                <button type="button" [disabled]="loading" (click)="openModal(templateSifremiUnuttum)"
                        class="btn btn-link btn-block"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="{{'sifremiUnuttumToolTip' | translate}}">{{'sifremiUnuttum' | translate}}</button>
              </div>
              <div class="col-md-4"></div>
            </div>

            <p></p>


            <!--//Hataların basıldığı alan-->
            <div class="row">
              <div class="col-md-6"></div>
              <div class="col-md-2">
                <div *ngIf="submitted && f.username.errors" style="color: red">
                  <div *ngIf="f.username.errors.required">{{'validTCKimlik' | translate}}!</div>
                </div>
              </div>
              <div class="col-md-4"></div>
            </div>

            <div class="row">
              <div class="col-md-6"></div>
              <div class="col-md-2">
                <div *ngIf="submitted && f.password.errors" style="color: red">
                  <div *ngIf="f.password.errors.required">{{'validSifreGiriniz' | translate}}!</div>
                </div>
              </div>
              <div class="col-md-4"></div>
            </div>


          </form>


        </div>
      </div>

      <br>
      <br>

      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-1" align="right"><font color="#ff0000" size="5">&nbsp;&nbsp;*</font></div>
        <div class="col-md-6">

          <p style="TEXT-ALIGN: justify"><font size="2">Bu bölüme;<br>
            <span id="lblAciklama_YardimAlan">
															11 haneli <b>T.C. kimlik
                                                        numarasının</b> yazılması gerekmektedir</span>
          </font></p>

        </div>
        <div class="col-md-3"></div>

      </div>
      <br>
      <br>

      <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-4">
          <a id="hlUyelikTuruDegistirYardimAlan" [routerLink]="['/bas-bss/main']"
             style="color:Blue;font-size:100%;"><u>Ana Sayfa'ya
            dönmek için tıklayınız</u></a>
        </div>
        <div class="col-md-4"></div>
      </div>
    </div>
  </div>


  <p></p>


</div>

<ng-template #templateSifremiUnuttum>
  <div class="modal-header">
    <label class="title">{{'sifremiUnuttum' | translate}}</label>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <form [formGroup]="sifremiUnuttumForm" (ngSubmit)="sendSifreSifirla()">

      <div class="form-group">
        <div class="row">

          <div class="col-md-3"></div>
          <div class="col-md-6">
            <!--<label><b>T.C. Kimlik No. :</b></label>-->
          </div>
          <div class="col-md-3"></div>
        </div>

        <div class="row">
          <div class="col-md-3"><b>T.C. Kimlik No.</b></div>
          <div class="col-md-7">
            <input type="text" (keypress)="keyPressNumbers($event)" formControlName="tckimlikNo" class="form-control"
                   maxlength="11"/>
          </div>


        </div>


      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeAndResetModal()" data-toggle="tooltip"
                data-placement="top" title="{{'vazgecToolTip' | translate}}">{{'kapat' | translate}}</button>
        <button type="submit" class="btn btn-primary"
                data-toggle="tooltip"
                data-placement="top"
                title="{{'sifremiSifirlaToolTipToolTip' | translate}}">{{'sifremiSifirla' | translate}}</button>
      </div>


    </form>


  </div>
</ng-template>


<footer class="footer fixed-bottom ">
  <div style="background: #fff">
    <p class="text-muted text-center">Tüm hakları saklıdır © {{currentYear}} <br> TSK Mehmetçik Vakfı</p>

  </div>

</footer>
