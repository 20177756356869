<app-header></app-header>
<div class="page-wrapper" style="padding-top: 5% ">
  <div align="center">
    <!--<td style="BACKGROUND-COLOR: white" valign="top" align="center" height="100%">-->
      <div id="pnlUserTypeNotDefined">

        <p>&nbsp;</p>
        <!--<div style="background-color: #F2F5F7; border: 1px solid #000000; padding: 10px 14px 10px 14px;
                                width: 400px; font: normal normal bold 14px 'Arial'">-->
          <div style="background-color: #FFFFFF; border: 1px solid #000000; padding: 10px 14px 10px 14px;
                                width: 400px; font: normal normal bold 14px 'Arial'">
          <p>
            TSK Mehmetçik Vakfı Bireysel Sorgulama ve Denetim Sistemine hoşgeldiniz.</p>
          <p>
            Bu sistemden istifade edilebilmesi için; bağışçılarımızın, yardım alan Mehmetçiklerimizin ve Mehmetçik
            yakınlarının T.C. Kimlik Numaralarının sisteme kayıtlı olması gerekmektedir.</p>
          <p>
            Saygılarımızla...</p>
        </div>
        <p></p>
        <br>
        <p><font size="4"><b>Lütfen kullanıcı türünü seçiniz.</b></font><br>
          <br>
        </p>
        <div class="row" align="center">
          <div class="col-md-3"> </div>
          <div class="col-md-3"><a href="/bas-bss/login?kullaniciTipi=1"><img src="assets/bagisYapan.png" border="0"></a></div>
          <div class="col-md-3"><a href="/bas-bss/login?kullaniciTipi=2"><img src="assets/yardimAlan.png" border="0"></a></div>
          <div class="col-md-3"> </div>

        </div>
        <p></p>

      </div>
      <br>

      <p></p>
    <!--</td>-->
  </div>
</div>


<footer class="footer fixed-bottom ">
  <div  style="background: #fff">
    <p class="text-muted text-center">Tüm hakları saklıdır © {{currentYear}} <br> TSK Mehmetçik Vakfı</p>

  </div>

</footer>
