import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NotificationService} from "../../services/notification.service";
import {ActivatedRoute, Router} from "@angular/router";
import {SifreService} from "../../services/sifre.service";
import {faEye, faGlobe} from "@fortawesome/free-solid-svg-icons";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-sifre-degistir',
  templateUrl: './sifre-degistir.component.html',
  styleUrls: ['./sifre-degistir.component.scss']
})
export class SifreDegistirComponent implements OnInit {
  sifreForm: FormGroup | any;
  hide: boolean = true;
  tckn: any;
  kullaniciTipi: any;
  submitted = false;
  sifreAdi: any;

  eyeIcon = faEye;
  fieldTextType: boolean | any;
  fieldEskiSifreTextType: boolean | any;
  repeatFieldTextType: boolean | any;
  modalRef: BsModalRef | any;


  @ViewChild('tplBekleyiniz', {static: true}) tplBekleyiniz !: TemplateRef<any>

  /**Counter*/
  intervalId = 0;
  message = '';
  seconds = 6;

  constructor(private formBuilder: FormBuilder,
              private notifyService: NotificationService,
              private sifreService: SifreService,
              private router: Router,
              private modalService: BsModalService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {


    let currentUser = JSON.parse(<string>localStorage.getItem('currentUser'));
    this.tckn = currentUser.tckn;
    this.kullaniciTipi = currentUser.kullaniciTipi;

    if (currentUser.sifreAktifmi) {//Şifre Değiştirme İşlemi
      this.sifreAdi="Mevcut Şifreniz"
    } else if (!currentUser.sifreAktifmi) {//İlk Defa Şifre Alma
      this.sifreAdi="Geçici Şifreniz"
    }

    this.sifreForm = this.formBuilder.group({
      "tckimlikNo": [{
        value: this.tckn,
        disabled: false
      }],
      "kullaniciTipi": [{
        value: this.kullaniciTipi,
        disabled: false
      }],
      eskiSifre: ['', Validators.required],
      yeniSifre: [null, [Validators.pattern("^(?=(?:\\D*\\d){2})[a-zA-Z0-9]*$"),Validators.required, Validators.minLength(8)]],
      yeniSifreTekrar: [null, [Validators.required, Validators.minLength(8)]],
    }, {validator: this.confirmedValidator('yeniSifre', 'yeniSifreTekrar')});


  }

  get f() {
    return this.sifreForm.controls
  }


  update() {
    if (!this.sifreForm.valid) {
      this.submitted = true;
      return;
    }
    this.sifreService.changePassword(this.sifreForm.getRawValue()).subscribe(
      response => {

        this.notifyService.translateNotification('Şifre Değiştirildi', 'basarili')
        this.showModal();
        this.start()
      },
      error => {
        this.notifyService.translateNotification(error.error, 'hata')
      }
    )

  }

  myFunction() {
    this.hide = !this.hide;
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  togglefieldEskiSifreTextType() {
    this.fieldEskiSifreTextType = !this.fieldEskiSifreTextType;
  }

  toggleRepeatFieldTextType() {
    this.repeatFieldTextType = !this.repeatFieldTextType;
  }

  confirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({confirmedValidator: true});
      } else {
        matchingControl.setErrors(null);
      }
    }

  }

  /**Counter **/

  clearTimer() {
    clearInterval(this.intervalId);
  }

  start() {
    this.countDown();
  }

  stop() {
    this.clearTimer();
    this.message = ``;
  }

  private countDown() {
    this.clearTimer();
    this.intervalId = window.setInterval(() => {
      this.seconds -= 1;
      if (this.seconds === 0) {
        this.router.navigate(['/bas-bss/login'],{ queryParams: { kullaniciTipi: this.kullaniciTipi } });
        this.modalService.hide();// Modal kapatılır
        this.message = 'Sayaç sıfırlandı!';
        this.clearTimer()
      } else {
        if (this.seconds < 0) {
          this.seconds = 5;
        } // reset
        this.message = `${this.seconds} saniye sonra Giriş Sayfasına yönlendirileceksiniz.`;


      }
    }, 1000);
  }


  showModal() {
    /*this.modalRef = this.modalService.show(templateDetay);*/
    this.modalRef = this.modalService.show(this.tplBekleyiniz, {class: 'modal-md'});
  }

}
