<app-header></app-header>
<div class="page-wrapper" style="text-align:center; vertical-align:top;">
  <div align="center" style="padding-top: 2%">
    <td style="background-color: white" valign="top" align="center" height="100%">
      <table align="center">
        <tbody align="center">
        <tr *ngIf="kullaniciTipi==2">
          <th colspan="2" align="center" style="color: red">
            Bireysel Sorgulama ve Denetim Sistemi'ne kayıt olabilmek için, daha önce TSK Mehmetçik Vakfından
            yardım almış olmanız gerekmektedir.
          </th>
        </tr>
        <tr *ngIf="kullaniciTipi==1">
          <th colspan="2" align="center" style="color: red">
            Bireysel Sorgulama ve Denetim Sistemi'ne kayıt olabilmek için, daha önce TSK Mehmetçik Vakfı'na her hangi
            bir bağışta bulunmuş olmanız gerekmektedir.
          </th>
        </tr>
        <tr>
          <th colspan="2" align="center">
            Sisteme kayıt için lütfen aşağıdaki bilgileri doldurunuz.
          </th>
        </tr>
        </tbody>
      </table>
      <div>
        <form [formGroup]="registerForm" (ngSubmit)="register()">
          <table align="center" border="0">
            <tbody>
            <tr>
              <td colspan="2" height="20">
              </td>
            </tr>
            <tr>
              <td>
                T.C. Kimlik No.
              </td>
              <td>

                <input name="txttckimlikNo" type="text" (keypress)="keyPressNumbers($event)" maxlength="11"
                       id="tckimlikNo" style="width:200px;" formControlName="tckimlikNo"><span
                *ngIf="registerForm.controls.tckimlikNo.invalid && submitted" class="hata">*</span>

              </td>
            </tr>
            <tr><td height="10"></td></tr>
            <tr>
              <td>
                Adı
              </td>
              <td>
                <input name="txtAd" type="text" maxlength="30" id="txtAd" style="width:200px;"
                       formControlName="ad"><span *ngIf="registerForm.controls.ad.invalid && submitted"
                                                  class="hata">*</span>
              </td>
            </tr>
            <tr><td height="10"></td></tr>
            <tr>
              <td>
                Soyadı
              </td>
              <td>
                <input name="txtSoyad" type="text" maxlength="30" id="txtSoyad" style="width:200px;"
                       formControlName="soyad"><span *ngIf="registerForm.controls.soyad.invalid && submitted"
                                                     class="hata">*</span>
              </td>
            </tr>
            <tr><td height="10"></td></tr>
            <tr *ngIf="kullaniciTipi==2">
              <td>
                Baba adı
              </td>
              <td>
                <input name="txtBabaAdi" type="text" maxlength="30" id="txtBabaAdi" style="width:200px;"
                       formControlName="babaAdi"><span *ngIf="registerForm.controls.babaAdi.invalid && submitted"
                                                       class="hata">*</span>
              </td>
            </tr>
            <tr><td height="10"></td></tr>
            <tr>
              <td>
                E-posta adresi
              </td>
              <!--<div class="form-group">
                <label for ="email">Email</label>
                <input type="text" class="form-control" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" id="email"name="email" ngModel #emailref="ngModel">
                <div *ngIf="emailref.errors &&(emailref.touched || emailref.dirty)" class ="alert alert-danger">
                  <div [hidden]="!emailref.errors?.pattern">
                    Invalid pattern
                  </div>
                </div>
              </div>-->
              <td>
                <input name="EmailRequired1:txtEmail" type="email" [email]="true" maxlength="50"
                       id="EmailRequired1_txtEmail"
                       style="width:200px;" formControlName="email"  >
                <span
                  *ngIf="registerForm.controls.email.invalid && submitted"
                  class="hata">*</span>
              </td>
            </tr>
            <tr><td height="10"></td></tr>
            <tr>
              <td valign="top">
                Adresi
              </td>
              <td>
                <textarea name="txtAdres" id="txtAdres" style="height:70px;width:200px;" maxlength="250"
                          formControlName="adres"></textarea><span
                *ngIf="registerForm.controls.adres.invalid && submitted"
                class="hata">*</span>
              </td>
            </tr>
            <tr><td height="10"></td></tr>
            <tr>
              <td valign="top">
                Adres&nbsp;ilçesi
              </td>
              <td>
                <input name="txtIlcesi" type="text" maxlength="50" id="txtIlcesi" style="width:200px;"
                       formControlName="ilce"><span
                *ngIf="registerForm.controls.ilce.invalid && submitted"
                class="hata">*</span>
              </td>
            </tr>
            <tr><td height="10"></td></tr>
            <tr>
              <td valign="top" height="1">
                Adres ili
              </td>
              <td height="1">
                <input name="txtIli" type="text" maxlength="50" id="txtIli" style="width:200px;"
                       formControlName="il"><span
                *ngIf="registerForm.controls.il.invalid && submitted"
                class="hata">*</span>
              </td>
            </tr>
            <tr><td height="10"></td></tr>
            <tr>
              <td>
                Ev telefonu
              </td>
              <td>
                <input name="txtEvTel" type="text" prefix="0" mask="(000) 000 00 00" [showMaskTyped]="true"
                       maxlength="20" id="txtEvTel" style="width:200px;"
                       formControlName="evTel"><span
                *ngIf="registerForm.controls.evTel.invalid && submitted"
                class="hata">*</span>
              </td>
            </tr>
            <tr><td height="10"></td></tr>
            <tr>
              <td>
                Cep telefonu
              </td>
              <td>
                <input name="txtCepTel" type="text" prefix="0" mask="(000) 000 00 00" [showMaskTyped]="true"
                       maxlength="20" id="txtCepTel" style="width:200px;"
                       formControlName="cepTel"><span
                *ngIf="registerForm.controls.cepTel.invalid && submitted"
                class="hata">*</span>
              </td>
            </tr>
            <tr><td height="10"></td></tr>
            <tr>
              <td>
                İş telefonu
              </td>
              <td>
                <input name="txtIsTel" type="text" prefix="0" mask="(000) 000 00 00" [showMaskTyped]="true"
                       maxlength="20" id="txtIsTel" style="width:200px;"
                       formControlName="isTel"><span
                *ngIf="registerForm.controls.isTel.invalid && submitted"
                class="hata">*</span>
              </td>
            </tr>

            <p> </p>
            <p> </p>
            <tr>
              <td></td>


              <td align="center" colspan="2">
                <button [disabled]="loading" type="submit" class="btn btn-primary btn-block" data-toggle="tooltip"
                        data-placement="top"
                        title="{{'bilgileriGonderToolTip' | translate}}">BİLGİLERİ GÖNDER
                </button>
              </td>
            </tr>

            <p> </p>
            <p> </p>
            <!--Hata Mesajları-->
            <tr>
              <td align="center" colspan="2" *ngIf="registerForm.controls.tckimlikNo.invalid && submitted"
                  class="hata">
                Lütfen T.C. kimlik numaranızı eksiksiz giriniz.
              </td>
            </tr>
            <tr>
              <td align="center" colspan="2" *ngIf="registerForm.controls.ad.invalid && submitted"
                  class="hata">
                Lütfen adınızı giriniz.
              </td>
            </tr>
            <tr>
              <td align="center" colspan="2" *ngIf="registerForm.controls.soyad.invalid && submitted"
                  class="hata">
                Lütfen soyadınızı giriniz.
              </td>
            </tr>
            <tr *ngIf="kullaniciTipi==2">
              <td align="center" colspan="2" *ngIf="registerForm.controls.babaAdi.invalid && submitted"
                  class="hata">
                Lütfen baba adınızı giriniz.
              </td>
            </tr>
            <tr>
              <td align="center" colspan="2" *ngIf="registerForm.controls.email.invalid && submitted"
                  class="hata">
                Lütfen geçerli bir e-posta adresinizi giriniz.
              </td>
            </tr>
            <tr>
              <td align="center" colspan="2" *ngIf="registerForm.controls.adres.invalid && submitted"
                  class="hata">
                Lütfen adresinizi giriniz.
              </td>
            </tr>
            <tr>
              <td align="center" colspan="2" *ngIf="registerForm.controls.ilce.invalid && submitted"
                  class="hata">
                Lütfen ilçenizi giriniz.
              </td>
            </tr>
            <tr>
              <td align="center" colspan="2" *ngIf="registerForm.controls.il.invalid && submitted"
                  class="hata">
                Lütfen ilinizi giriniz.
              </td>
            </tr>
            <tr>
              <td align="center" colspan="2" *ngIf="registerForm.controls.evTel.invalid && submitted"
                  class="hata">
                Lütfen geçerli Ev Telefonu giriniz.
              </td>
            </tr>
            <tr>
              <td align="center" colspan="2" *ngIf="registerForm.controls.cepTel.invalid && submitted"
                  class="hata">
                Lütfen geçerli Cep Telefonu giriniz.
              </td>
            </tr>
            <tr>
              <td align="center" colspan="2" *ngIf="registerForm.controls.isTel.invalid && submitted"
                  class="hata">
                Lütfen geçerli İş Telefonu giriniz.
              </td>
            </tr>
            <tr>
              <td align="center" colspan="2">
                &nbsp;
              </td>
            </tr>


            <!--Hata mesajları ^^-->


            <p></p>
            <tr>
              <td colspan="2" align="center">
                <a id="hlAnaSayfa" [routerLink]="['/bas-bss/main']" style="color:Blue;">Ana Sayfa'ya dönmek için
                  tıklayınız.</a>
              </td>
            </tr>
            </tbody>
          </table>
        </form>
      </div>
    </td>
  </div>
</div>

<footer class="footer fixed-bottom">
  <div  style="background: #fff">
    <p class="text-muted text-center">Tüm hakları saklıdır © {{currentYear}} <br> TSK Mehmetçik Vakfı</p>

  </div>

</footer>


<ng-template #tplBekleyiniz>


  <div class="modal-header ">
    <label class="title-modal">Yeni Kayıt İşlemi</label>
  </div>
  <div class="modal-body">

    <div> {{message}}</div>

  </div>


</ng-template>
