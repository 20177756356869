import { Component, OnInit } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss']
})
export class AppLayoutComponent implements OnInit {

  constructor(private translateService: TranslateService) {
    this.translateService.addLangs(['tr','en']);
    const browserLang= this.translateService.getBrowserLang();
    //Browser language en-tr ise bu dili kullan değilse en kullan
    this.translateService.use(browserLang.match(/en|tr/) ?browserLang : 'en');}

  ngOnInit(): void {
  }

}
