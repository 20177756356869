<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">Bağış Bilgileri</li>
  </ol>
</nav>
<div class="row" align="center">
  <div class="col-md-1"></div>
  <div class="col-md-3"></div>
  <div class="col-md-4"><b>T.C. KİMLİK NO.: <a style="color: blue"> {{tckn}} </a></b></div>
  <div class="col-md-3"></div>
  <div class="col-md-1"></div>
</div>
<br>
<br>
<div class="row " align="center" >
  <div class="col-md-1"></div>
  <div class="col-md-3">
    <table class="table table-hover table-bordered">
      <thead class="thead-light">
      <tr align="center">
        <th colspan="2">MENKUL BAĞIŞ BİLGİLERİ</th>
      </tr>
      <tr *ngIf="(!menkulNull)" align="center" >
      <th scope="col">Bağış Tarihi</th>
        <th scope="col">Bağış Tutarı</th>
      </tr>
      <tr *ngIf="(menkulNull)" align="center" >
        <td style="color: darkgreen"><b style="color: darkred">Kayıtlı Menkul bağışınız bulunmamaktadır.</b></td>
      </tr>
      </thead>
      <tbody align="center">
      <tr *ngFor="let rows of rowsMenkulBagisBilgileri">
        <td>{{rows.bagisTarihi | date :  "dd.MM.y"}}</td>
        <td>{{rows.bagisMiktari | currency:'':''}} &nbsp; {{ rows.kod}} </td>
      </tr>

      </tbody>
    </table>

  </div>


  <div class="col-md-4">
    <table class="table table-hover table-bordered" >
      <thead class="thead-light">
      <tr align="center">
        <th colspan="3">GAYRİMENKUL BAĞIŞ BİLGİLERİ</th>
      </tr>
      <tr *ngIf="(!gmenkulNull)" align="center" >
      <th scope="col">Bağış Tarihi</th>
        <th scope="col">Bağış Türü</th>
        <th scope="col">Bağış Şekli</th>
      </tr>
      <tr *ngIf="(gmenkulNull)" align="center" >
        <td style="color: darkgreen"><b style="color: darkred">Kayıtlı Gayrimenkul bağışınız bulunmamaktadır.</b></td>
      </tr>
      </thead>
      <tbody align="center">
      <tr *ngFor="let rows of rowsGMenkulBagisBilgileri">
        <td>{{rows.edinmeTaihi | date :  "dd.MM.y"}}</td>
        <td *ngIf="rows.gayriMenkulCinsi ==1"> Ev</td>
        <td *ngIf="rows.gayriMenkulCinsi ==2"> İşyeri</td>
        <td *ngIf="rows.gayriMenkulCinsi ==3"> Arsa</td>
        <td *ngIf="rows.gayrimenkulBagisSekli ==1"> Şartsız</td>
        <td *ngIf="rows.gayrimenkulBagisSekli ==2"> İntifa</td>
      </tr>

      </tbody>
      <tbody *ngIf="(!gmenkulNull)" align="center">
      <tr><td></td>
        <td><button type="button" class="btn btn-primary" (click)="showModal(templateDetay)">Gayrimenkul Detay Göster
      </button>
        </td>
      <td></td></tr>

      </tbody>
    </table>

  </div>


  <div class="col-md-3">
    <table class="table table-hover table-bordered">
      <thead class="thead-light">
      <tr align="center">
        <th colspan="5">VEKALETEN KURBAN BAĞIŞ BİLGİLERİ</th>
      </tr>
      <tr *ngIf="(!kurbanNull)" align="center" >
        <th scope="col" >Bağış Tarihi</th>
        <th scope="col">Bağış Miktarı-(Adet)</th>
        <th scope="col">Kesimhane</th>
        <th scope="col">Sonuç</th>
      </tr>
      <tr *ngIf="(kurbanNull)" align="center" >
        <td style="color: darkgreen"><b style="color: darkred">Kayıtlı Kurban bağışınız bulunmamaktadır.</b></td>
      </tr>
      </thead>
      <tbody align="center">
      <tr *ngFor="let rows of rowsKurbanBagisBilgileri">

        <td *ngIf="rows.ad!= null">{{rows.bagisTarihi | date :  "dd.MM.y"}}</td>
        <td *ngIf="rows.ad!= null">{{rows.adet}}</td>
        <td *ngIf="rows.kesimYapilacakmi ==0 && rows.ad!= null"> Kesimsiz</td>
        <td *ngIf="rows.gayriMenkulCinsi ==1&& rows.ad!= null"> Kesimli</td>
        <td *ngIf="rows.ad!= null">{{rows.ad}}</td>
        <td *ngIf="rows.kesimSiraNo ==-1 && rows.ad!= null"> </td>
        <td *ngIf="rows.kesimSiraNo !=-1 && rows.ad!= null">{{rows.kesimSiraNo}}. SIRADA KESİM YAPILMIŞTIR. </td>



      </tr>
      </tbody>
    </table>


  </div>


  <div class="col-md-1"></div>


</div>


<!--<div class="row" align="center">
  <div class="form-group col-md-1"></div>
  <div class="form-group col-md-3"></div>
  <div class="form-group col-md-4">
    <button type="button" class="btn btn-primary" (click)="showModal(templateDetay)">Gayrimenkul Detay Göster
    </button>
  </div>
  <div class="form-group col-md-3"></div>
  <div class="form-group col-md-1"></div>


</div>-->

<div class="row" align="center">
  <div class="col-md-1"></div>
  <div class="col-md-3"></div>

<div class="col-md-4">
  <table class="table table-hover table-bordered" >
    <thead class="thead-light">
    <tr align="center">
      <th colspan="3">VAKIF TARAFINDAN GÖNDERİLEN BELGE VE OBJELER</th>
    </tr>
    <tr *ngIf="(!odulNull)" align="center" >
      <th scope="col">Gönderilme Tarihi</th>
      <th scope="col">Belge - Obje Türü</th>
    </tr>
    <tr *ngIf="(odulNull)" align="center" >
      <td style="color: darkgreen"><b style="color: darkred">Kayıtlı belge ve objeniz bulunmamaktadır.</b></td>
    </tr>
    </thead>
    <tbody align="center">
    <tr *ngFor="let rows of rowsOdulBilgileri">
      <td>{{rows.odulGondermeTarihi | date :  "dd.MM.y"}}</td>
      <td>{{rows.odulAdi}}</td>

    </tr>

    </tbody>
  </table>


</div>
  <div class="col-md-3"></div>
  <div class="col-md-1"></div>
</div>



<ng-template #templateDetay>
  <div class="modal-header" style="background-color: #4B89FC">
    <label style="color: #fbfcfc" class="title-modal">{{'gayrimenkulDetay' | translate}}</label>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <ngb-carousel>
      <!--<ngb-carousel>-->
      <ng-template ngbSlide *ngFor="let rows of imageSource">
        <div>
          <!--<img [src]="imageSource" class="img-fluid" width="200">-->
          <!--<img [src]="rows" alt="sadasd">-->

          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-4">
                  <p><b>Adres: </b></p>
                </div>
                <div class="col-md-8">
                  <p>{{rows.adres}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <p><b>İli: </b></p>
                </div>
                <div class="col-md-8">
                  <p>{{rows.ilAd}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <p><b>İlçesi: </b></p>
                </div>
                <div class="col-md-8">
                  <p>{{rows.ilce}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <p><b>Mevkii: </b></p>
                </div>
                <div class="col-md-8">
                  <p>{{rows.mevkii}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <p><b>Oda Sayısı: </b></p>
                </div>
                <div class="col-md-8">
                  <p>{{rows.odaSayisi}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <p><b>Metrekaresi: </b></p>
                </div>
                <div class="col-md-8">
                  <p>{{rows.metrekare}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <p><b>İnşaat Tarihi: </b></p>
                </div>
                <div class="col-md-8">
                  <p>{{rows.insaatTarihi | date :  "dd.MM.y"}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <p><b>Katı: </b></p>
                </div>
                <div class="col-md-8">
                  <p>{{rows.kat}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <p><b>Raiç Değer: </b></p>
                </div>
                <div class="col-md-8">
                  <p>{{rows.raicDeger | currency:'':''}} &nbsp; {{ rows.kod}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <p><b>Raiç Değer Tarihi: </b></p>
                </div>
                <div class="col-md-8">
                  <p>{{rows.raicTarihi | date :  "dd.MM.y"}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <p><b>Diğer Özellikler: </b></p>
                </div>
                <div class="col-md-8">
                  <p>{{rows.ozellikleri}}</p>
                </div>
              </div>


            </div>
            <div class="col-md-6">
              <img [src]="rows.src" class="img-fluid">
            </div>
          </div>


          <!--<img [src]="images[0]" alt="Random first slide">-->
        </div>
        <div class="carousel-caption">

          <!--<h3>İstanbul</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>-->
        </div>
      </ng-template>

    </ngb-carousel>


  </div>
</ng-template>
