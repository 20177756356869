<div class="content-wrapper">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">Yardıma Esas Mehmetçik Bilgileri</li>
      <!--<li class="breadcrumb-item" aria-current="page">{{'bagisBildirimleri'|translate}}</li>-->
    </ol>
  </nav>
  <div class="container">

    <div class="col-md-6 offset-3 "><label style=" padding-top: 2%"><b> </b>
    </label></div>
    <div class="row">
      <div class="col-sm">

        <div class="form-row">
          <div class="form-group col-md-3"></div>
          <div class="form-group col-md-3">
            <label><b>T.C. Kimlik No.</b></label>
          </div>
          <div class="col-md-1">:</div>
          <div class="form-group col-md-3">
            <label>{{tckn}}</label>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-3"></div>
          <div class="form-group col-md-3">
            <label><b>Adı ve Soyadı</b></label>
          </div>
          <div class="col-md-1">:</div>
          <div class="form-group col-md-3">
            <label>{{adSoyad}}</label>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-3"></div>
          <div class="form-group col-md-3">
            <label><b>Baba Adı</b></label>
          </div>
          <div class="col-md-1">:</div>
          <div class="form-group col-md-3">
            <label>{{babaAdi}}</label>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-3"></div>
          <div class="form-group col-md-3">
            <label><b>Doğum Yeri</b></label>
          </div>
          <div class="col-md-1">:</div>
          <div class="form-group col-md-3">
            <label>{{dogumYeri}}</label>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-3"></div>
          <div class="form-group col-md-3">
            <label><b>Doğum Tarihi</b></label>
          </div>
          <div class="col-md-1">:</div>
          <div class="form-group col-md-3">
            <label>{{dogumTarihi}}</label>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-3"></div>
          <div class="form-group col-md-3">
            <label><b>Birlik Adı</b></label>
          </div>
          <div class="col-md-1">:</div>
          <div class="form-group col-md-3">
            <label>{{birlikAdi}}</label>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-3"></div>
          <div class="form-group col-md-3">
            <label><b>Olay Yeri</b></label>
          </div>
          <div class="col-md-1">:</div>
          <div class="form-group col-md-3">
            <label>{{olayYeri}}</label>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-3"></div>
          <div class="form-group col-md-3">
            <label><b>Olay Tarihi</b></label>
          </div>
          <div class="col-md-1">:</div>
          <div class="form-group col-md-3">
            <label>{{olayTarihi}}</label>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-3"></div>
          <div class="form-group col-md-3">
            <label><b>Durumu</b></label>
          </div>
          <div class="col-md-1">:</div>
          <div class="form-group col-md-3">
            <label>{{durumu}}</label>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-3"></div>
          <div class="form-group col-md-3">
            <label><b>Sebebi</b></label>
          </div>
          <div class="col-md-1">:</div>
          <div class="form-group col-md-3">
            <label>{{vefatEngellikSebebi}}</label>
          </div>
        </div>


      </div>


      <div class="col-sm" *ngIf="imageFlag">
        <img [src]="imageSource" class="img-fluid" width="200">
      </div>
    </div>
  </div>


</div>
