import {Component, OnInit, TemplateRef} from '@angular/core';
import {NotificationService} from "../../../services/notification.service";
import {NgxSpinnerService} from "ngx-spinner";
import {DomSanitizer} from "@angular/platform-browser";
import {map} from "rxjs/operators";
import {BagisciService} from "../../../services/bagisci.service";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CommonService} from "../../../services/common.service";


@Component({
  selector: 'app-bagisci-bilgileri',
  templateUrl: './bagisci-bilgileri.component.html',
  styleUrls: ['./bagisci-bilgileri.component.scss']
})
export class BagisciBilgileriComponent implements OnInit {
  tckn: any;
  adSoyad: any;
  adres: any;
  ilceAdi: any;
  adresIl: any;
  evTel: any;
  cepTel: any;
  isTel: any;
  email: any;
  image: any;
  imageSource: any;
  ad: any;
  soyad: any;
  kisiBilgisi: any;
  currentToken:any;

  ilBilgisi: any;

  ilListesi: any = [];
  ilceListesi: any = [];


  modalRef: BsModalRef | any;
  editForm: FormGroup | any;
  imageFlag: any;
  ilAdi: any;

  constructor(private notifyService: NotificationService,
              private bagisciService: BagisciService,
              private spinner: NgxSpinnerService,
              private formBuilder: FormBuilder,
              private modalService: BsModalService,
              private sanitizer: DomSanitizer,
              private commonService: CommonService) {

    let currentUser = JSON.parse(<string>localStorage.getItem('currentUser'));

    this.tckn = currentUser.tckn
    this.currentToken = currentUser.token



    this.kisiBilgisi = {
      tckn: this.tckn,
      currentToken:this.currentToken
    }

  }

  ngOnInit(): void {
    this.showSpinner()

    this.getAllData(this.kisiBilgisi)

    this.getIlListesi();
  }

  getAllData(kisiBilgisi: any) {

    this.bagisciService.getAll(kisiBilgisi).subscribe(
      response => {
        this.tckn = response.tckn
        if (response.ad && response.soyad) {
          this.adSoyad = response.ad + ' ' + response.soyad;
        }
        this.ad = response.ad
        this.soyad = response.soyad
        this.adres = response.adres
        this.ilceAdi = response.ilceAdi
        this.adresIl = response.adresIl
        this.evTel = response.evTel
        this.cepTel = response.cepTel
        this.isTel = response.isTel
        this.email = response.email


        this.image = response.bagisciResim
        this.imageFlag = false;
        if (this.image) {
          this.imageFlag = true;
        }

        this.imageSource = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${this.image}`);
        this.spinner.hide();
      }, error => {
        this.notifyService.translateNotification(error.error, 'hata')
        this.spinner.hide();
      }
    )
  }

  showSpinner() {
    this.spinner.show();
  }


  showModal(template: TemplateRef<any>) {
    this.editForm = this.formBuilder.group({

      "tckn": [{
        value: this.tckn,
        disabled: true
      }],
      "ad": [this.ad, [Validators.required, Validators.pattern('^[A-Za-ziİüÜöÖşŞğĞçÇı ]+$')]],
      "soyad": [this.soyad, [Validators.required, Validators.pattern('^[A-Za-ziİüÜöÖşŞğĞçÇı ]+$')]],
      "adres": [this.adres, [Validators.required]],
      "ilceAdi": [{
        value: this.ilceAdi,
        disabled: false
      }],
      "adresIl": [{
        value: this.adresIl,
        disabled: false
      }],
      "evTel": [this.evTel, [Validators.pattern('.{8,}$')]],
      "cepTel": [this.cepTel, [Validators.pattern('.{8,}$')]],
      "isTel": [this.isTel, [Validators.pattern('.{8,}$')]],
      "email": [this.email, [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],

    });
    this.modalRef = this.modalService.show(template, {class: 'modal-lg'});

  }

  updateBagisYapanBilgileri() {
    this.editForm.value.tckn = this.tckn

     if(this.ilAdi != null){
       this.editForm.value.adresIl = this.ilAdi.ad
     }
    this.bagisciService.updatebagisciBilgileri(this.editForm.value).subscribe(
      response => {
        this.spinner.hide();
        this.closeAndResetModal();
        this.notifyService.translateNotification('Bilgi güncelleştirme talebiniz alınmıştır. Gerekli kontroller yapıldıktan sonra güncelleştirme işlemi gerçekleştirilecektir.', 'basarili')
      },
      error => {
        this.notifyService.translateNotification(error.error, 'hata')
        this.spinner.hide();
        this.closeAndResetModal();
      }
    )
  }

  closeAndResetModal() {
    this.modalRef.hide();
    this.editForm.reset();
  }

  get f() {
    return this.editForm.controls
  }


  populateCity(value: any) {
    this.ilBilgisi = {
      ilId: value.value
    }
    this.ilAdi=this.ilListesi[value.value];
    this.ilceListesi = this.getIlce(this.ilBilgisi);

  }


  getIlListesi() {

    this.commonService.getIl().subscribe(
      response => {
        this.ilListesi = response;

      }, error => {
        this.notifyService.translateNotification(error.error, 'hata')
        this.spinner.hide();
      }
    )

  }


  getIlce(ilId: any) {

    this.commonService.getIlce(ilId).subscribe(
      response => {
        this.ilceListesi = response;

      }, error => {
        this.notifyService.translateNotification(error.error, 'hata')
        this.spinner.hide();
      }
    )

  }

}
